import React, { useState, useEffect, useContext } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";
// import { roomTypeUrl, roomUrl, imgUrl } from "../../utils/baseUrl";
import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

/* import es from 'date-fns/locale/es';
registerLocale('es', es) */

import moment from "moment";
/* import "moment/locale/th"; */

import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import SimpleTimeInput from "react-simple-time-input";

import {
  roomTypeUrl,
  roomUrl,
  imgUrl,
  pricePersonUrl,
  priceBedUrl,
  checkinUrl,
} from "../../utils/baseUrl";

import { RentContext } from "../../context/RentContext";

const RoomPayment = () => {
  const {
    adults,
    setAdults,
    convertType,
    setConvertType,
    payMethod,
    setPayMethod,
    startDate,
    checkinDate,
    setCheckinDate,
    checkoutDate,
    setCheckoutDate,
    checkinTime,
    setCheckinTime,
    checkoutTime,
    setCheckoutTime,
    setStartDate,
    roomBooking,
    setRoomBooking,
    guarantee,
    setGuarantee,
    priceDisNormal,
    setPriceDisNormal,
    priceDisSaleDisSale,
    setPriceDisSaleDisSale,
    pricePerson,
    setPricePerson,
    priceBed,
    setPriceBed,
    numPricePerson,
    numPriceBed,
    numDays,
    setNumdays,
    handleClick,
  } = useContext(RentContext);
  // onClick={() => setAdults(li.name)}
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [roomTypeData, setRoomTypeData] = useState([]);
  const [priceTypeData, setPriceTypeData] = useState([]);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [cardID, setCardID] = useState("");
  const [comments, setComments] = useState("");
  const [blanketSmQty, setBlanketSmQty] = useState("0");
  const [blanketSmPrice, setBlanketSmPrice] = useState("0");
  const [blanketBigQty, setBlanketBigQty] = useState("0");
  const [blanketBigPrice, setBlanketBigPrice] = useState("0");
  // const [roomBooking, setRoomBooking] = useState({});
  //   const [checkinDate, setCheckinDate] = useState("");
  // const [checkoutDate, setCheckoutDate] = useState("");
  //   const [checkinTime, setCheckinTime] = useState("");
  // const [checkoutTime, setCheckoutTime] = useState("");
  // const [convertType, setConvertType] = useState("");
  //   const [plusPay, setPlusPay] = useState("");
  // const [payMethod, setPayMethod] = useState("");
  // const [startDate, setStartDate] = useState(new Date());
  /*   const [value, onChange] = useState(new Date());
  const [time, setTime] = useState("14:00"); */

  function readRoomTypeData() {
    axios.get(`${roomTypeUrl}read.php`).then(function (response) {
      console.log(response.data);
      setRoomTypeData(response.data);
      setPriceTypeData(response.data);
    });
  }
  const parseDate = (str) => {
    const [month, day, year] = str.split("/");
    return new Date(year, month - 1, day);
  };
  const datediff = (first, second) => {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  };

  useEffect(() => {
    const id = localStorage.getItem("id");
    setUserId(id);
    readRoomTypeData();
    // create new date in the format of yy / mm / dd "YYYY/MM/DD"
    let start_Date = moment(checkinDate).format("MM/DD/YYYY");
    let end_Date = moment(checkoutDate).format("MM/DD/YYYY");
    // let totalDays = end_Date.diff(start_Date, "days");
    //month, day, year
    const diff = datediff(
      parseDate(start_Date.toString()),
      parseDate(end_Date.toString())
    );
    console.log("CIN: ", start_Date, " , ", end_Date);
    console.log("TT Days: ", diff);
    if (diff !== 0) {
      setNumdays(diff);
    } else {
      setNumdays(Number(diff) + 1);
    }
  }, []);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    // alert(convertType);
    // alert(numDays);
    /*     alert(pricePerson + "" + priceBed);
    alert(payMethod + " " + convertType); */
    // const [firstname, setFirstname] = useState("");
    if (firstname === "") {
      Swal.fire({
        position: "center",
        icon: "question",
        title: "ระบุชื่อลูกค้าด้วย",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    setLoading(true);
    axios
      .post(`${checkinUrl}savecheckin.php`, {
        userId,
        checkinDate,
        checkinTime,
        checkoutDate,
        checkoutTime,
        firstname,
        lastname,
        cardID,
        phone,
        selectedRoom: roomBooking?.id, //
        name: roomBooking?.name,
        price: roomBooking?.price, //
        guarantee,
        pricePerson,
        priceBed,
        numPricePerson,
        numPriceBed,
        priceDisNormal: priceDisNormal ? priceDisNormal : 0,
        priceDisSaleDisSale: priceDisSaleDisSale ? priceDisSaleDisSale : 0,
        payMethod,
        convertType,
        numDays,
        comments,
        blanketSmQty,
        blanketSmPrice,
        blanketBigQty,
        blanketBigPrice,
      })
      .then(function (response) {
        console.log(response.data);
        //numPricePerson, setNumPricePerson,numPriceBed, setNumPriceBed
        // readData(id);  const [payMethod, setPayMethod] = useState("");
        // const [convertType, setConvertType] = useState("");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        //printbill/:id
        window.location.href = "printbill/" + response.data.lastID;
        // navigate("printbill/" + response.data.lastID);
      });
  };
  return (
    <div className="h-screen overflow-y-auto">
      {/* RoomDetail {JSON.stringify(roomBooking)} */}
      {/*       <div>
        <h1>
          {JSON.stringify(checkinDate)}/{JSON.stringify(checkoutDate)}
        </h1>
      </div> */}
      <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
        <h3 className="font-medium text-2xl text-black dark:text-white">
          กำหนดรายละเอียดลูกค้า
        </h3>
      </div>
      <form onSubmit={handleOnSubmit}>
        <div className="p-5">
          <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                ชื่อลูกค้า
              </label>
              <input
                type="text"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                นามสกุล
              </label>
              <input
                type="text"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                เลขบัตรประชาชน
              </label>
              <input
                type="text"
                value={cardID}
                onChange={(e) => setCardID(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                โทรศัพท์
              </label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
          </div>
          <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                จำนวนผ้าห่มพื้นเล็ก
              </label>
              <input
                type="number"
                value={blanketSmQty}
                onChange={(e) => setBlanketSmQty(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                รวมราคาผ้าห่มพื้นเล็กทั้งหมด
              </label>
              <input
                type="number"
                value={blanketSmPrice}
                onChange={(e) => setBlanketSmPrice(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                จำนวนผ้าห่มพื้นใหญ่
              </label>
              <input
                type="number"
                value={blanketBigQty}
                onChange={(e) => setBlanketBigQty(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                รวมราคาผ้าห่มพื้นใหญ่ทั้งหมด
              </label>
              <input
                type="number"
                value={blanketBigPrice}
                onChange={(e) => setBlanketBigPrice(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
          </div>

          <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
            {/*             <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                เปลี่ยนประเภทห้องพัก
              </label>
              <select
                value={convertType}
                onChange={(e) => setConvertType(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              >
                <option value=""></option>
                {priceTypeData.map((item, index) => (
                  <option
                    key={index}
                    value={`${item.id}/${item.price}/${item.title}`}
                  >
                    {item.title}({item.price})
                  </option>
                ))}
              </select>
            </div> */}
            {/*             <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                วิธีชำระเงิน
              </label>
              <select
                value={payMethod}
                onChange={(e) => setPayMethod(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              >
                <option value=""></option>
                <option value="เงินสด">เงินสด</option>
                <option value="เงินโอน">เงินโอน</option>
              </select>
            </div> */}
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                จำนวนวันเข้าพัก
              </label>
              <input
                type="number"
                value={numDays}
                onChange={(e) => setNumdays(e.target.value)}
                className="w-full text-lg rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                หมายเหตุ(แจ้งพนักงานกะต่อไป)
              </label>
              <input
                type="text"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
          </div>
          <div className="grid gap-4 grid-cols-1 md:grid-cols-4"></div>
          <button
            className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
            disabled={loading}
            type="submit"
          >
            {loading ? <>กรุณาซักครู่..</> : <>บันทึก</>}
          </button>
        </div>
      </form>
    </div>
  );
};

export default RoomPayment;
