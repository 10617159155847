import React from "react";
import { Breadcrumb } from "../components";
import { Outlet, Link } from "react-router-dom";
// import { IconName } from "react-icons/ai";
import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";

const SumReport = () => {
  return (
    <>
      <Breadcrumb pageName={"สรุปรายรับ-รายจ่าย"} />
      <div className="overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="p-4">
          <div className="flex flex-col xl:flex-row items-center justify-end gap-1 md:p-4 rounded-md overflow-hidden">
            {/*             <Link
              to={""}
              className="flex w-[150px] justify-center rounded bg-primary p-1 font-medium text-gray"
            >
              รายรับ/รายจ่าย
            </Link> */}
            {/*             <Link
              to={""}
              className="flex w-[150px] justify-center rounded bg-primary p-1 font-medium text-gray"
            >
              เช่ารายปี
            </Link> */}

            {/*             <Link
              to={""}
              className="flex w-[150px] justify-center rounded bg-primary p-1 font-medium text-gray"
            >
              จองห้องพัก
            </Link> */}
            {/*             <Link
              to={""}
              className="flex w-[150px] justify-center rounded bg-primary p-1 font-medium text-gray"
            >
              ค่าใช้จ่าย
            </Link> */}
            {/*             <Link
              to={""}
              className="flex w-[150px] justify-center rounded bg-primary p-1 font-medium text-gray"
            >
              เงินเดือน
            </Link> */}
            {/*             <Link
              to={""}
              className="flex w-[150px] justify-center rounded bg-primary p-1 font-medium text-gray"
            >
              ขายสินค้า
            </Link> */}
          </div>
        </div>
        {/* ---------  */}
        <div className="p-5">
          <Outlet />
        </div>
        {/* ---------  */}
      </div>
    </>
  );
};

export default SumReport;
