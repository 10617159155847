import React from "react";
import moment from "moment/min/moment-with-locales";
moment.locale("th");

const SumSale = ({ sumcheckin }) => {
  let net = 0;
  console.log("Ck In: ", sumcheckin);
  console.log("Ck In len: ", sumcheckin[0].length);
  return (
    <>
      <div>
        <table className="w-full max-w-[100px] table-auto mx-4 mt-8">
          <thead>
            <tr className="bg-gray-2 text-left dark:bg-meta-4">
              <th className="border max-w-[100px] font-medium text-black">
                ขายสินค้า
              </th>
            </tr>
          </thead>
          <tbody>
            {sumcheckin[0].map((item, idx) => {
              net += Number(item?.total);
              return (
                <tr key={idx}>
                  <td className="border">{item?.total ? item?.total : 0}</td>
                </tr>
              );
            })}
            <tr>
              <td className="border bg-gray">{net ? net : 0}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SumSale;
