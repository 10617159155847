import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";
import { productUrl } from "../../utils/baseUrl";

const AddProduct = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      productName: "",
      price: "",
      stock: "",
    },
  });
  const [loading, setLoading] = useState(false);
  //products id,productName,price,stock
  const handleOnSubmit = (data) => {
    setLoading(true);

    //-------------
    axios
      .post(`${productUrl}add.php`, {
        productName: data.productName,
        price: data.price,
        stock: data.stock,
      })
      .then(function (response) {
        console.log(response.data);
        reset();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      });
    //-------------else

    //firstname,lastname,phone,email,password,pltype
  };
  return (
    <div>
      <div className="rounded-sm bg-red-500 border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          {/*           <h3 className="font-medium text-black dark:text-white">
New Category
</h3> */}
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="p-6.5">
              {/*           <div className="mb-4.5">
        <label className="mb-2.5 block text-black dark:text-white">
          ชื่ออุปกรณ์
        </label>
        <input
          type="text"
          name="name"
          {...register("name", {
            required: true,
          })}
          className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
        />
        {errors.name && (
          <div className="my-3 text-normal text-danger italic">
            ป้อนชื่ออุปกรณ์
          </div>
        )}
      </div> */}
              <div className="mb-2">
                <label>ชื่อสินค้า</label>
                <input
                  type="text"
                  name="productName"
                  {...register("productName", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.productName && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    ป้อนชื่อสินค้า
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label>ราคาสินค้า</label>
                <input
                  type="number"
                  name="price"
                  {...register("price", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.price && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    ป้อนราคาสินค้า
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label>สต็อกสินค้า</label>
                <input
                  type="text"
                  name="stock"
                  {...register("stock", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.stock && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    ป้อนสต็อกสินค้า
                  </div>
                )}
              </div>

              <button
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                disabled={loading}
                type="submit"
              >
                {loading ? <>กรุณาซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
