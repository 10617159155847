import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { roomTypeUrl, roomUrl, imgUrl } from "../../utils/baseUrl";
const EditRoom = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [selectedFile, setSectedFile] = useState(null);
  const [roomTypeData, setRoomTypeData] = useState([]);
  const [roomTypeId, setRoomTypeId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [size, setSize] = useState("");
  const [maxPerson, setMaxPerson] = useState("");
  const [price, setPrice] = useState("");

  function readData(id) {
    axios.get(`${roomUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
      setRoomTypeId(response.data?.roomTypeId);
      setName(response.data?.name);
      setDescription(response.data?.description);
      setSize(response.data?.size);
      setMaxPerson(response.data?.maxPerson);
      setPrice(response.data?.price);
      ////rooms id,roomTypeId,name,description,size,maxPerson,price,photo,facilityId,createdAt
    });
  }
  function readRoomTypeData() {
    axios.get(`${roomTypeUrl}read.php`).then(function (response) {
      console.log(response.data);
      setRoomTypeData(response.data);
    });
  }

  useEffect(() => {
    readData(id);
    readRoomTypeData();
  }, []);
  console.log(datas);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("roomTypeId", roomTypeId);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("size", size);
    formData.append("maxPerson", maxPerson);
    formData.append("price", price);
    formData.append("idEdit", id);
    setLoading(true);
    axios.post(`${roomUrl}update.php`, formData).then(function (response) {
      console.log(response.data);
      readData(id);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="rounded-sm bg-red-500 border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            แก้ไขห้องพัก {id}
          </h3>
          <form onSubmit={handleOnSubmit}>
            <div className="p-6.5">
              <div>
                {datas?.photo != null ? (
                  <img
                    src={`${imgUrl}${datas?.photo}`}
                    className="rounded-lg mb-4 md:h-[300px] object-cover"
                  />
                ) : (
                  "-"
                )}
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  ประเภทห้องพัก
                </label>
                <select
                  value={roomTypeId}
                  onChange={(e) => setRoomTypeId(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                >
                  <option value=""></option>
                  {/* <option value="0">มองเห็นทั้งหมด</option> */}
                  {roomTypeData.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  ชื่อห้อง/เลขห้อง
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  รายละเอียด
                </label>
                <input
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  ขนาดห้อง
                </label>
                <input
                  type="text"
                  value={size}
                  onChange={(e) => setSize(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  จำนวนผู้เข้าพักสูงสุด
                </label>
                <input
                  type="text"
                  value={maxPerson}
                  onChange={(e) => setMaxPerson(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  ราคาห้องพัก
                </label>
                <input
                  type="text"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="my-2">
                <label>รูปภาพ</label>
                <div className="flex flex-col items-start">
                  <input type="file" onChange={handleOnChange} />
                </div>
              </div>
              <button
                disabled={loading}
                type="submit"
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? <>กรุณาซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditRoom;
