import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";
import {
  roomTypeUrl,
  roomUrl,
  imgUrl,
  pricePersonUrl,
  priceBedUrl,
  checkinUrl,
} from "../../utils/baseUrl";
import { useReactToPrint } from "react-to-print";

// import moment from "moment/min/moment-with-locales";
import moment from "moment";
moment.locale("th");
const PrintBill = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [checkinData, setCheckinData] = useState({});
  const [comment, setComment] = useState("");
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function readCheckinData(id) {
    axios
      .get(`${checkinUrl}readcheckinbyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setCheckinData(response.data);
      });
  }

  useEffect(() => {
    readCheckinData(id);
  }, []);

  console.log("Print: ", checkinData);
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการเช่าห้องพัก
          </h4>
          <div
            className="mt-[100px] max-w-full overflow-x-auto"
            ref={componentRef}
          >
            {/* ----------  */}
            <div className="w-full my-[20px]">
              <div className="p-[20px]">
                ลูกค้า {checkinData?.firstname} {checkinData?.lastname}
              </div>
              <div></div>
            </div>
            {/*             <div className="w-full flex flex-col md:flex-row">
              <div>วันที่เข้าพัก</div>
              <div>{checkinData?.checkInDate}</div>
            </div>
            <div className="w-full">
              <div>วันที่คืนห้องพัก</div>
              <div>{checkinData?.checkOutDate}</div>
            </div>
            <div className="w-full">
              <div>ห้องพัก</div>
              <div> {checkinData?.roomno}</div>
            </div>
            <div className="w-full">
              <div>เงินประกัน</div>
              <div>{checkinData?.guarantee}</div>
            </div> */}

            {/* -------  */}
            {/* ----------  */}
            {/* -------*/}
            <table className="w-full table-auto ml-4">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[130px] font-medium text-black dark:text-white">
                    วันที่เข้าพัก
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    วันที่คืนห้องพัก
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    ห้องพัก/สถานะ
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    จำนวนวันเข้าพัก
                  </th>

                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    ราคาห้อง
                  </th>
                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    รวมเงิน
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-b border-[#eee] dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {moment(checkinData?.checkInDate).format("L")}
                    </h5>
                    <p className="text-sm"> {checkinData?.checkInTime}</p>
                  </td>
                  <td className="border-b border-[#eee] dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {moment(checkinData?.checkOutDate).format("L")}
                    </h5>
                    <p className="text-sm"> {checkinData?.checkOutTime}</p>
                  </td>
                  <td className="border-b border-[#eee] dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {" "}
                      {checkinData?.roomno}
                    </h5>
                    <p className="text-sm"> {checkinData?.status}</p>
                  </td>

                  <td className="border-b border-[#eee] dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.numofday}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.price}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.price * checkinData?.numofday}
                    </h5>
                  </td>
                </tr>
                <tr className="p-2">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="bg-gray-2 text-left dark:bg-meta-4">
                    รวมเงิน
                  </td>
                  <td className="bg-gray-2 text-left dark:bg-meta-4">
                    {Number(checkinData?.net) +
                      Number(
                        checkinData?.personplus * checkinData?.numofpersonplus
                      ) +
                      Number(checkinData?.bedplus * checkinData?.numofbedplus)}
                  </td>
                </tr>
                <tr className="p-2">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="bg-gray-2 text-left dark:bg-meta-4">ส่วนลด</td>
                  <td className="bg-gray-2 text-left dark:bg-meta-4">
                    {checkinData?.discount}
                  </td>
                </tr>
                <tr className="p-2">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="bg-gray-2 text-left dark:bg-meta-4">
                    รวมเงินหลังหักส่วนลด
                  </td>
                  <td className="bg-gray-2 text-left dark:bg-meta-4">
                    {Number(checkinData?.total) +
                      Number(
                        checkinData?.personplus * checkinData?.numofpersonplus
                      ) +
                      Number(checkinData?.bedplus * checkinData?.numofbedplus)}
                  </td>
                </tr>
                <tr className="">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="bg-gray-2 text-left dark:bg-meta-4">
                    เงินประกัน
                  </td>
                  <td className="bg-gray-2 text-left dark:bg-meta-4">
                    {checkinData?.guarantee}
                  </td>
                </tr>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <td></td>
                  <td></td>
                  <td className="">เพิ่มคน/เพิ่มเตียง</td>
                  <td className="">
                    {checkinData?.personplus * checkinData?.numofpersonplus}/
                    {checkinData?.bedplus * checkinData?.numofbedplus}
                  </td>
                  <td className="">ส่วนลดปกติ/ส่วนลดเซลล์</td>
                  <td className="">
                    {checkinData?.priceDisNormal}/
                    {checkinData?.priceDisSaleDisSale}
                  </td>
                </tr>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <td></td>
                  <td></td>
                  <td className="">
                    จำนวนผ้าห่มพื้นเล็ก/รวมราคาผ้าห่มพื้นเล็กทั้งหมด
                  </td>
                  <td className="">
                    {checkinData?.blanketsmqty}/{checkinData?.blanketsmprice}
                  </td>
                  <td className="">
                    จำนวนผ้าห่มพื้นใหญ่/รวมราคาผ้าห่มพื้นใหญ่ทั้งหมด
                  </td>
                  <td className="">
                    {checkinData?.blanketbigqty}/{checkinData?.blanketbigprice}
                  </td>
                </tr>
              </tbody>
            </table>
            {/* -------  */}
          </div>
          <div className="my-[150px] w-full justify-center rounded">
            <button
              className="flex w-[200px] p-3 justify-center rounded bg-primary font-medium text-gray"
              onClick={handlePrint}
            >
              พิมพ์
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintBill;
