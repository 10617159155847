import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";
import { employeeUrl, imgUrl } from "../../utils/baseUrl";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

const AddSalary = () => {
  //   const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      employeeId: "",
      expensetype: "",
      expensesdate: "",
      description: "",
      total: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [salaryData, setSalaryData] = useState([]);
  const [expenseTypeData, setExpenseTypeData] = useState([]);

  function readEmpData() {
    axios.get(`${employeeUrl}read.php`).then(function (response) {
      console.log(response.data);
      setEmpData(response.data);
    });
  }
  function readSalaryData() {
    axios.get(`${employeeUrl}readsalary.php`).then(function (response) {
      console.log(response.data);
      setSalaryData(response.data);
    });
  }
  ////expensetype id,expensetypename
  function readExpenseType() {
    axios.get(`${employeeUrl}readexpensetype.php`).then(function (response) {
      console.log(response.data);
      setExpenseTypeData(response.data);
    });
  }

  useEffect(() => {
    readEmpData();
    readSalaryData();
    readExpenseType();
  }, []);
  console.log(empData);
  console.log(expenseTypeData);

  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      console.log(id);
      if (result.isConfirmed) {
        axios
          .delete(`${employeeUrl}deletesalary.php/${id}`)
          .then(function (response) {
            console.log(response.data);
            readEmpData();
            readSalaryData();
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };
  //   employees id,firstName,lastName,address,phone,positions,createdAt
  const handleOnSubmit = (data) => {
    setLoading(true);

    //-------------
    //expensetype id,expensetypename
    axios
      .post(`${employeeUrl}addsalary.php`, {
        employeeId: data.employeeId,
        expensesdate: data.expensesdate,
        expensetype: data.expensetype,
        description: data.description,
        total: data.total,
      })
      .then(function (response) {
        console.log(response.data);
        reset();
        readSalaryData();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      });
    //-------------else

    //firstname,lastname,phone,email,password,pltype
  };
  return (
    <div>
      <div className="rounded-sm bg-red-500 border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="p-6.5">
              {/*           <div className="mb-2">
            <label>เลขที่ใบเสร็จ</label>
            <input
              type="text"
              name="billno"
              {...register("billno", {
                required: true,
              })}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
            {errors.billno && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                ป้อนเลขที่ใบเสร็จ
              </div>
            )}
          </div> */}
              <div className="mb-2">
                <label>ข้อมูลพนักงาน</label>
                <select
                  {...register("employeeId", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                >
                  <option value=""></option>
                  {/* <option value="0">มองเห็นทั้งหมด</option> */}
                  {empData.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item?.firstName} {item?.lastName}({item?.positions}/
                      {item?.salary})
                    </option>
                  ))}
                </select>
                {errors.employeeId && (
                  <div className="my-3 text-normal text-danger italic">
                    ระบุข้อมูลพนักงาน
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label>ประเภทรายการ</label>
                <select
                  {...register("expensetype", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                >
                  <option value=""></option>
                  {/*//expensetype id,expensetypename <option value="0">มองเห็นทั้งหมด</option> */}
                  {expenseTypeData.map((item, index) => (
                    <option
                      key={index}
                      value={`${item.id}/${item.expensetypename}`}
                    >
                      {item?.expensetypename}
                    </option>
                  ))}
                </select>
                {errors.expensetype && (
                  <div className="my-3 text-normal text-danger italic">
                    ระบุประเภทรายการ
                  </div>
                )}
              </div>

              <div className="mb-2">
                <label>ประจำเดือน</label>
                <input
                  type="date"
                  name="expensesdate"
                  {...register("expensesdate", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.expensesdate && (
                  <div className="my-3 text-normal text-danger italic">
                    ป้อนประจำเดือน
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label>รายละเอียดเพิ่มเติม</label>
                <input
                  type="text"
                  name="description"
                  {...register("description", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.description && (
                  <div className="my-3 text-normal text-danger italic">
                    ป้อนรายละเอียดเพิ่มเติม
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label>รวมเงินที่หักออก/เบิกเงิน</label>
                <input
                  type="number"
                  name="total"
                  {...register("total", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.total && (
                  <div className="my-3 text-normal text-danger italic">
                    ป้อนรวมเงินที่หักออก
                  </div>
                )}
              </div>
              <button
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                disabled={loading}
                type="submit"
              >
                {loading ? <>กรุณาซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
        <div>
          <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
              ข้อมูลเงินเดือน
            </h4>
            <div className="max-w-full  overflow-scroll">
              {/* --------  */}
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-left dark:bg-meta-4">
                    <th className="min-w-[220px] font-medium text-black dark:text-white xl:pl-2">
                      ข้อมูลพนักงาน
                    </th>
                    <th className="min-w-[150px] font-medium text-black dark:text-white">
                      เงินเดือน(บาท)
                    </th>
                    <th className="font-medium text-black dark:text-white">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {salaryData.map((item, index) => (
                    <tr key={index}>
                      <td className="border-b border-[#eee] dark:border-strokedark xl:pl-2">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.employeename}
                        </h5>
                        <p className="text-sm"> {item?.expensesdate}</p>
                        <p className="text-sm"> {item?.description}</p>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        {item?.net}/{item?.total}
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <div className="flex items-center space-x-3.5">
                          <button
                            onClick={() => deleteData(item?.id)}
                            className="hover:text-primary"
                          >
                            <IoTrashOutline size={22} />
                          </button>
                          {/*                         <Link to={`editemployee/${item?.id}`} className="">
                          <HiMiniPencilSquare size={22} />
                        </Link> */}
                          {/*                         <Link to={`addsalary/${item?.id}`} className="">
                          บันทึกเงินเดือน
                        </Link> */}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* --------  */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSalary;
