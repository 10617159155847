import React, { useState, useEffect, useContext } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";
// import { roomTypeUrl, roomUrl, imgUrl } from "../../utils/baseUrl";
import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

/* import es from 'date-fns/locale/es';
registerLocale('es', es) */

/* import moment from "moment";
import "moment/locale/th"; */

import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import SimpleTimeInput from "react-simple-time-input";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

// import { bookingUrl } from "../../utils/baseUrl";

import {
  roomTypeUrl,
  roomUrl,
  imgUrl,
  pricePersonUrl,
  priceBedUrl,
  checkinUrl,
  bookingUrl,
} from "../../utils/baseUrl";

import { RentContext } from "../../context/RentContext";
//Asia/Bangkok 09:27 pm +07
/* var dd = moment.locale(‘th’)
 this.date = moment().add(543, ‘year’).format(‘LLLL’) */
/* import moment from "moment";
moment.locale("th"); */
import moment from "moment/min/moment-with-locales";
moment.locale("th");

const AddBooking = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [roomTypeData, setRoomTypeData] = useState([]);
  const [priceTypeData, setPriceTypeData] = useState([]);

  const [checkinDate, setCheckinDate] = useState(new Date());
  const [checkoutDate, setCheckoutDate] = useState(new Date());

  const [guarantee, setGuarantee] = useState("100");
  const [convertType, setConvertType] = useState("");
  const [payMethod, setPayMethod] = useState("");
  const [price, setPrice] = useState("0");
  const [pricePerson, setPricePerson] = useState("0");
  const [priceBed, setPriceBed] = useState("0");

  const [isCheckedPerson, setIsCheckedPerson] = useState(false);
  const [isCheckedBed, setIsCheckedBed] = useState(false);
  const [isCheckedDiscount, setIsCheckedDiscount] = useState(false);
  const [isCheckedSaleDiscount, setIsCheckedSaleDiscount] = useState(false);

  const [pricePersonData, setPricePersonData] = useState([]);
  const [priceBedData, setPriceBedData] = useState([]);

  const [priceDisNormal, setPriceDisNormal] = useState("100");
  const [priceDisSaleDisSale, setPriceDisSaleDisSale] = useState("100");
  const [comments, setComments] = useState("");
  const [numDays, setNumdays] = useState("1");
  const [numOfRoom, setNumOfRoom] = useState("1");

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [deposit, setDeposit] = useState("0");
  function readRoomTypeData() {
    axios.get(`${roomTypeUrl}read.php`).then(function (response) {
      console.log(response.data);
      setRoomTypeData(response.data);
      setPriceTypeData(response.data);
    });
  }

  function readPricePerson() {
    axios.get(`${pricePersonUrl}read.php`).then(function (response) {
      console.log(response.data);
      setPricePersonData(response.data);
    });
  }
  function readPriceBed() {
    axios.get(`${priceBedUrl}read.php`).then(function (response) {
      console.log(response.data);
      setPriceBedData(response.data);
    });
  }

  const handlePersonOnChange = () => {
    setIsCheckedPerson(!isCheckedPerson);
  };
  const handleBedOnChange = () => {
    setIsCheckedBed(!isCheckedBed);
  };
  const handleDiscountOnChange = () => {
    setIsCheckedDiscount(!isCheckedDiscount);
  };
  const handleSaleDiscountOnChange = () => {
    setIsCheckedSaleDiscount(!isCheckedSaleDiscount);
  };

  useEffect(() => {
    const id = localStorage.getItem("id");
    setUserId(id);
    readRoomTypeData();
    readPricePerson();
    readPriceBed();
    console.log(
      "Start date3: ",
      checkinDate,
      ", ",
      " End date4: ",
      checkoutDate
    );
    /*     setCheckinDate()
      setCheckoutDate() */
    // const x = moment().add(3,'hours')
    /*     setCheckoutTime(moment(new Date().getTime()).add(3, "hours").format("LT"));
    setCheckinTime(moment(new Date().getTime()).format("LT")); */
    /*     console.log("CkIN Time::: ", checkinTime);
    console.log("CkOUT Time::: ", checkoutTime);

    var beginningTime = checkinTime;

    console.log("Begin Time22: ", beginningTime);
    if (beginningTime.toString() > "17:00") {
      setIsCheckedDiscount(true);
      setPricePerson(100);
      console.log("Hello: ");
    } else {
      setIsCheckedDiscount(false);
    } */
    // console.log("Now: ", checkoutTime);
  }, []);
  const handleConfirmClick = (e) => {
    e.preventDefault();
    /*     if (payMethod === "") {
        Swal.fire({
          position: "center",
          icon: "question",
          title: "ระบุวิธีชำระเงินด้วย",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }*/

    if (convertType === "") {
      Swal.fire({
        position: "center",
        icon: "question",
        title: "กำหนดประเภทห้องด้วย",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    if (payMethod === "") {
      Swal.fire({
        position: "center",
        icon: "question",
        title: "ระบุวิธีชำระเงินด้วย",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    if (firstname === "") {
      Swal.fire({
        position: "center",
        icon: "question",
        title: "ระบุชื่อลูกค้าด้วย",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    setLoading(true);
    axios
      .post(`${bookingUrl}savebooking.php`, {
        userId,
        checkinDate,
        checkoutDate,
        firstname,
        lastname,
        email,
        phone,
        deposit,
        guarantee,
        pricePerson,
        priceBed,
        priceDisNormal: priceDisNormal ? priceDisNormal : 0,
        priceDisSaleDisSale: priceDisSaleDisSale ? priceDisSaleDisSale : 0,
        payMethod,
        convertType,
        numDays,
        numOfRoom,
        comments,
      })
      .then(function (response) {
        console.log(response.data);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        //printbill/:id
        window.location.href = "/booking";
        // window.location.href = "printbill/" + response.data.lastID;
        // navigate("printbill/" + response.data.lastID);
      });

    /*     Swal.fire({
      title: "แจ้งเตือน",
      text: "กรุณายืนยันการชำระเงิน...",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
 
      if (result.isConfirmed) {

      }
    }); */
  };
  /*     userId,numOfRoom,checkInDate,checkOutDate,deposit,roomtype,price,numofday,
    personplus,bedplus,guarantee,paymentMethod,firstName,lastName,phone,email,comment,status */
  return (
    <div className="h-screen  overflow-scroll">
      {/* RoomDetail {JSON.stringify(roomBooking)} */}
      <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
        <h3 className="font-medium text-2xl text-black dark:text-white">
          กำหนดรายละเอียดห้องพัก123
        </h3>
      </div>

      <form onSubmit={handleConfirmClick}>
        <div className="p-5">
          <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                วันที่เข้าพัก
              </label>
              <DatePicker
                selected={checkinDate}
                onChange={(date) => setCheckinDate(date)}
                className="w-full text-lg rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>

            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                วันที่คืนห้อง
              </label>
              <DatePicker
                selected={checkoutDate}
                onChange={(date) => setCheckoutDate(date)}
                className="w-full text-lg rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                ประเภทห้องพัก
              </label>
              <select
                value={convertType}
                onChange={(e) => setConvertType(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              >
                <option value=""></option>
                {/* <option value="0">มองเห็นทั้งหมด</option> */}
                {priceTypeData.map((item, index) => (
                  <option
                    key={index}
                    value={`${item.id}/${item.price}/${item.title}`}
                  >
                    {item.title}({item.price})
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                วิธีชำระเงิน
              </label>
              <select
                value={payMethod}
                onChange={(e) => setPayMethod(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              >
                <option value=""></option>
                <option value="เงินสด">เงินสด</option>
                <option value="เงินโอน">เงินโอน</option>
              </select>
            </div>
          </div>
          <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
            <div className="mb-4.5">
              <h1 className="mb-2.5 block text-black dark:text-white">
                เพิ่มคน
              </h1>
              {/* const [isCheckedPerson, setIsCheckedPerson] = useState(false); */}
              <div className="flex gap-2 w-full">
                <input
                  type="checkbox"
                  id="some_id"
                  checked={isCheckedPerson}
                  onChange={handlePersonOnChange}
                />
                <label htmlFor="some_id">
                  {/* เพิ่มคน({isCheckedPerson ? "Yes" : "No"}) */}
                </label>
                {isCheckedPerson ? (
                  <div className="mb-2 w-full">
                    <select
                      value={pricePerson}
                      onChange={(e) => setPricePerson(e.target.value)}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    >
                      {/* <option value=""></option> */}
                      {/* <option value="0">มองเห็นทั้งหมด</option> */}
                      {pricePersonData.map((item, index) => (
                        <option key={index} value={item.price}>
                          {item.price}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mb-4.5">
              {/* const [isCheckedBed, setIsCheckedBed] = useState(false); */}
              <h1 className="mb-2.5 block text-black dark:text-white">
                เพิ่มเตียง
              </h1>
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  id="some_id2"
                  checked={isCheckedBed}
                  onChange={handleBedOnChange}
                />
                <label htmlFor="some_id2">
                  {/* เพิ่มเตียง({isCheckedBed ? "Yes" : "No"}) */}
                </label>
                {isCheckedBed ? (
                  <div className="mb-2 w-full">
                    <select
                      value={priceBed}
                      onChange={(e) => setPriceBed(e.target.value)}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    >
                      {/* <option value=""></option> */}
                      {/* <option value="0">มองเห็นทั้งหมด</option> */}
                      {priceBedData.map((item, index) => (
                        <option key={index} value={item.price}>
                          {item.price}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mb-4.5">
              <h1 className="mb-2.5 block text-black dark:text-white">
                ส่วนลดปกติ
              </h1>
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  id="some_id3"
                  checked={isCheckedDiscount}
                  onChange={handleDiscountOnChange}
                />
                <label htmlFor="some_id3">
                  {/* Emergency({isCheckedDiscount ? "Yes" : "No"}) */}
                </label>
                {isCheckedDiscount ? (
                  <>
                    <input
                      type="number"
                      value={priceDisNormal}
                      onChange={(e) => setPriceDisNormal(e.target.value)}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    />
                  </>
                ) : null}
              </div>
            </div>
            {/*       const [priceDisNormal, setPriceDisNormal] = useState("0");
const [priceDisSaleDisSale, setPriceDisSaleDisSale] = useState("0");; */}
            <div className="mb-4.5">
              <h1 className="mb-2.5 block text-black dark:text-white">
                ส่วนลดเซลล์
              </h1>
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  id="some_id4"
                  checked={isCheckedSaleDiscount}
                  onChange={handleSaleDiscountOnChange}
                />
                <label htmlFor="some_id4">
                  {/* ส่วนลดเซลล์({isCheckedSaleDiscount ? "Yes" : "No"}) */}
                </label>
                {isCheckedSaleDiscount ? (
                  <>
                    <input
                      type="number"
                      value={priceDisSaleDisSale}
                      onChange={(e) => setPriceDisSaleDisSale(e.target.value)}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                ชื่อลูกค้า
              </label>
              <input
                type="text"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                นามสกุล
              </label>
              <input
                type="text"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                อีเมล์
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                โทรศัพท์
              </label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
          </div>
          <div className="grid gap-4 grid-cols-1 md:grid-cols-5">
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                ค่าประกันห้อง
              </label>
              <input
                type="number"
                value={guarantee}
                onChange={(e) => setGuarantee(e.target.value)}
                className="w-full text-lg rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>

            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                จำนวนวันเข้าพัก
              </label>
              <input
                type="number"
                value={numDays}
                onChange={(e) => setNumdays(e.target.value)}
                className="w-full text-lg rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                จำนวนห้องพัก
              </label>
              <input
                type="number"
                value={numOfRoom}
                onChange={(e) => setNumOfRoom(e.target.value)}
                className="w-full text-lg rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                จำนวนเงินมัดจำ
              </label>
              <input
                type="number"
                value={deposit}
                onChange={(e) => setDeposit(e.target.value)}
                className="w-full text-lg rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                หมายเหตุ
              </label>
              <input
                type="text"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            {/*           <div className="mb-4.5">
        <label className="mb-2.5 block text-black dark:text-white">
          จำนวนวันเข้าพัก
        </label>
        <input
          type="number"
          value={numDays}
          onChange={(e) => setNumdays(e.target.value)}
          className="w-full text-lg rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
        />
      </div> */}
          </div>
          {/* --------  */}
          <button
            className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
            disabled={loading}
            type="button"
            onClick={(e) => handleConfirmClick(e)}
          >
            {loading ? <>กรุณาซักครู่..</> : <>บันทึกการจอง</>}
          </button>
          {/* --------  */}
        </div>
      </form>
    </div>
  );
};

export default AddBooking;
