import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";
import { expensesUrl, imgUrl } from "../../utils/baseUrl";

const AddExpenses = () => {
  //expensesUrl
  //expenses id,billno,expensesdate,description,total,createdAt
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      billno: "",
      expensesdate: "",
      description: "",
      total: "",
    },
  });
  const [loading, setLoading] = useState(false);
  //   employees id,firstName,lastName,address,phone,positions,createdAt
  const handleOnSubmit = (data) => {
    setLoading(true);

    //-------------
    axios
      .post(`${expensesUrl}add.php`, {
        billno: data.billno,
        expensesdate: data.expensesdate,
        description: data.description,
        total: data.total,
      })
      .then(function (response) {
        console.log(response.data);
        reset();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      });
    //-------------else

    //firstname,lastname,phone,email,password,pltype
  };
  return (
    <div>
      <div className="rounded-sm bg-red-500 border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="p-6.5">
              <div className="mb-2">
                <label>เลขที่ใบเสร็จ</label>
                <input
                  type="text"
                  name="billno"
                  {...register("billno", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.billno && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    ป้อนเลขที่ใบเสร็จ
                  </div>
                )}
              </div>

              <div className="mb-2">
                <label>วันที่บันทึก</label>
                <input
                  type="date"
                  name="expensesdate"
                  {...register("expensesdate", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.expensesdate && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    ป้อนวันที่บันทึก
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label>รายละเอียด</label>
                <input
                  type="text"
                  name="description"
                  {...register("description", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.description && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    ป้อนรายละเอียด
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label>รวมเงิน</label>
                <input
                  type="number"
                  name="total"
                  {...register("total", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.total && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    ป้อนรวมเงิน
                  </div>
                )}
              </div>
              <button
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                disabled={loading}
                type="submit"
              >
                {loading ? <>กรุณาซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddExpenses;
