import React, { useState, useEffect, useRef } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";

import {
  roomTypeUrl,
  roomUrl,
  imgUrl,
  pricePersonUrl,
  priceBedUrl,
  checkinUrl,
  incomeUrl,
} from "../../utils/baseUrl";

import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from "react-to-print";
import SumIncome from "./SumIncome";
import SumExpenses from "./SumExpenses";
import SumSale from "./SumSale";
import moment from "moment/min/moment-with-locales";
moment.locale("th");
//SumExpenses.js
const Report1 = () => {
  var date = new Date(new Date());
  // date.setDate(date.getDate() + 1);
  const [startDate, setStartDate] = useState(new Date());
  const [checkinDate, setCheckinDate] = useState("");
  // const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(date.setDate(date.getDate() + 1));
  const [incomeData, setIncomeData] = useState([]);
  const [checkinWithDateData, setCheckinWithDateData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  let total = 0;
  let net = 0;

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    searchCompany(searchTerm);
    setSearchItem(searchTerm);
  };

  function searchCompany(txtOcc) {
    setIsLoading(true);
    /*     const result = detail.replace(/[^0-9]/g, '');
    let result2 = result.slice(-5);
    console.log(result2); */
    axios
      // .get(`${shopUrl}searchoccupation.php/${txtOcc}`)
      .post(`${incomeUrl}income.php`, {
        searchText: txtOcc,
      })
      .then(function (response) {
        console.log("Search---------");
        const data = response.data;
        console.log("Type: ", typeof data);
        console.log(response?.data);
        console.log("---------Search");
        // setMemberData(response?.data);
        setIncomeData(response.data);
        // memberID
        // readLogo(response?.data.memberID);
        setIsLoading(false);
      });
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function readData() {
    axios.get(`${incomeUrl}income.php`).then(function (response) {
      console.log(response.data);
      setIncomeData(response.data);
    });
  }

  useEffect(() => {
    // readData();
  }, []);
  console.log(incomeData);

  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="flex justify-end">
          <button
            className="flex w-[200px] justify-center rounded bg-primary p-2 font-medium text-gray"
            onClick={handlePrint}
          >
            พิมพ์
          </button>
        </div>
        <div className="rounded-sm border border-stroke bg-white pt-1 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          {/*           <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการคืนห้องพัก
          </h4> */}
          <input
            type="date"
            className="w-full mb-2 border py-2 px-3 text-grey-darkest"
            value={searchItem}
            onChange={handleInputChange}
            placeholder={`ค้นหา`}
          />

          <div ref={componentRef} className="w-full overflow-hidden">
            {/* --------  */}
            {Object.keys(incomeData).length !== 0 ? (
              <>
                <div className="flex">
                  <SumIncome sumcheckin={incomeData?.checkin} />
                  <SumSale sumcheckin={incomeData?.sale} />
                  <SumExpenses sumcheckin={incomeData?.expenses} />

                  {/* <SumIncome sumcheckin={incomeData?.checkin} /> */}
                </div>
              </>
            ) : (
              <></>
            )}
            {/* --------  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Report1;
