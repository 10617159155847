import React from "react";
import { Breadcrumb } from "../components";
import { Outlet, Link } from "react-router-dom";
// import { IconName } from "react-icons/ai";
import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";
//BsFileEarmarkBarGraph
import { BsFileEarmarkBarGraph } from "react-icons/bs";
const Home = () => {
  return (
    <>
      <Breadcrumb pageName={"หน้าหลัก"} />
      <div className="overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        {/*         <div className="relative z-20 h-35 p-4">
          <div className="flex items-center justify-end  gap-1 md:p-4 rounded-md overflow-hidden">
            <Link to={""} className="w-[100px] flex gap-1">
              <BsFileEarmarkBarGraph size={22} />
              เช่ารายวัน
            </Link>
            <Link to={"rpwithmonth"} className="w-[120px] flex gap-1">
              <BsFileEarmarkBarGraph size={22} />
              เช่ารายเดือน
            </Link>
            <Link to={"rpwithyear"} className="w-[100px] flex gap-1">
              <BsFileEarmarkBarGraph size={22} />
              เช่ารายปี
            </Link>
          </div>
        </div> */}
        {/* ---------  */}
        <div className="p-5">
          <Outlet />
        </div>
        {/* ---------  */}
      </div>
    </>
  );
};

export default Home;
