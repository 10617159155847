import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";

import {
  roomTypeUrl,
  roomUrl,
  imgUrl,
  pricePersonUrl,
  priceBedUrl,
  checkinUrl,
} from "../../utils/baseUrl";

import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";

import moment from "moment";
moment.locale("th");
/* import moment from "moment/min/moment-with-locales";
moment.locale("th"); */
const ViewAllRoomCheckin = () => {
  const [checkinData, setCheckinData] = useState([]);

  function readCheckinData() {
    axios.get(`${checkinUrl}readcheckinbetween.php`).then(function (response) {
      console.log(response.data);
      setCheckinData(response.data);
    });
  }

  useEffect(() => {
    readCheckinData();
  }, []);
  console.log(checkinData);
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการเช่าห้องพักxxx
          </h4>
          <div className="max-w-full  overflow-scroll">
            {/* -------- 
//expenses id,billno,expensesdate,description,total,createdAt
*/}
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[120px] font-medium text-black dark:text-white">
                    วันที่เข้าพัก
                  </th>
                  <th className="min-w-[120px] font-medium text-black dark:text-white">
                    วันที่คืนห้อง
                  </th>
                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    ห้องพัก
                  </th>
                  <th className="min-w-[120px] font-medium text-black dark:text-white">
                    รวมเงิน
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    เพิ่มคน/เตียง
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    ส่วนลดปกติ/เซลล์
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    ยอดทั้งหมด
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    ยอดหักส่วนลด
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    วิธีชำระเงิน
                  </th>
                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    สถานะ
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <p className="text-sm"> {item?.phone}</p> */}
                {checkinData.map((item, index) => (
                  <React.Fragment key={`cancle_${index}`}>
                    <tr>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {moment(item?.checkInDate).format("L")}
                        </h5>
                        <p className="text-sm"> {item?.checkInTime}</p>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {moment(item?.checkOutDate).format("L")}
                        </h5>
                        <p className="text-sm"> {item?.checkOutTime}</p>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.roomno}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.price}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          <p className="text-sm">
                            {" "}
                            {item?.personplus * item?.numofpersonplus}(
                            {item?.numofpersonplus})/
                            {item?.bedplus * item?.numofbedplus}(
                            {item?.numofbedplus})
                          </p>
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          <p className="text-sm">
                            {" "}
                            {item?.priceDisNormal}/{item?.priceDisSaleDisSale}
                          </p>
                        </h5>
                      </td>

                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.net}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.total}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.paymentMethod}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <div className="flex items-center space-x-3.5">
                          <p className="text-sm"> {item?.status}</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="10"
                        className="border-b border-[#eee]  dark:border-strokedark"
                      >
                        <h5 className="font-medium text-black dark:text-white">
                          หมายเหตุ
                        </h5>
                        <p className="text-sm"> {item?.comments}</p>
                        <h5 className="font-medium text-black dark:text-white">
                          สาเหตุยกเลิกการเช่า
                        </h5>
                        <p className="text-sm"> {item?.cancleText}</p>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            {/* --------  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAllRoomCheckin;
