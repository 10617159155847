import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";
import { adminUrl, employeeUrl } from "../../utils/baseUrl";

const AddAdmin = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userId: "",
      username: "",
      password: "",
      role: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [isEmail, setIsEmail] = useState(false);
  const [empData, setEmpData] = useState([]);

  function readEmpData() {
    axios.get(`${employeeUrl}read.php`).then(function (response) {
      console.log(response.data);
      setEmpData(response.data);
    });
  }

  useEffect(() => {
    readEmpData();
  }, []);
  console.log(empData);
  const handleOnSubmit = (data) => {
    setLoading(true);
    axios
      .post(`${adminUrl}checkemail.php`, {
        // pid: userId,
        username: data.username,
      })
      .then(function (response) {
        console.log(response.data);
        if (response.data.status === 1) {
          setIsEmail(true);
          setLoading(false);
          setUser(response.data);
        } else {
          //-------------
          axios
            .post(`${adminUrl}add.php`, {
              userId: data.userId,
              username: data.username,
              password: data.password,
              role: data.role,
            })
            .then(function (response) {
              console.log(response.data);
              reset();
              setIsEmail(false);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              setLoading(false); // Stop loading
            });
          //-------------else
        }
      });
  };
  //users id,userId,username,password,role,createdAt
  //adminUrl
  return (
    <div>
      <div className="rounded-sm bg-red-500 border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          {/*           <h3 className="font-medium text-black dark:text-white">
New Category
</h3> */}
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="p-6.5">
              {/*           <div className="mb-4.5">
        <label className="mb-2.5 block text-black dark:text-white">
          ชื่ออุปกรณ์
        </label>
        <input
          type="text"
          name="name"
          {...register("name", {
            required: true,
          })}
          className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
        />
        {errors.name && (
          <div className="my-3 text-normal text-danger italic">
            ป้อนชื่ออุปกรณ์
          </div>
        )}
      </div> */}
              <div className="mb-2">
                <label>พนักงาน</label>
                <select
                  {...register("userId", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                >
                  <option value=""></option>
                  {/* <option value="0">มองเห็นทั้งหมด</option> */}
                  {empData.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.firstName} {item.lastName}
                    </option>
                  ))}
                </select>
                {errors.userId && (
                  <div className="my-3 text-normal text-danger italic">
                    ระบุพนักงาน
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label>ชื่อผู้ใช้งาน</label>
                <input
                  type="text"
                  name="username"
                  {...register("username", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.username && (
                  <div className="my-3 text-normal text-danger italic">
                    ป้อนชื่อผู้ใช้งาน
                  </div>
                )}
                {isEmail && (
                  <div className="my-3 text-normal text-danger italic">
                    Account already exists”, it means that the email address
                    you're trying to add, has been registered as an account
                    already
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label>รหัสผ่าน</label>
                <input
                  type="password"
                  name="password"
                  {...register("password", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.password && (
                  <div className="my-3 text-normal text-danger italic">
                    ป้อนรหัสผ่าน
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label>สิทธ์การใช้งาน</label>
                <select
                  {...register("role", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                >
                  <option value=""></option>
                  {/* <option value="0">มองเห็นทั้งหมด</option> */}
                  <option value={"Manager"}>ผู้จัดการ</option>
                  <option value={"Employee"}>พนักงาน</option>
                </select>
                {errors.role && (
                  <div className="my-3 text-normal text-danger italic">
                    ระบุพนักงาน
                  </div>
                )}
              </div>
              <button
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                disabled={loading}
                type="submit"
              >
                {loading ? <>กรุณาซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
