import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";
import { pricePersonUrl } from "../../utils/baseUrl";

const AddPricePerson = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      price: "",
    },
  });
  const [loading, setLoading] = useState(false);
  //products id,productName,price,stock
  const handleOnSubmit = (data) => {
    setLoading(true);

    //-------------
    axios
      .post(`${pricePersonUrl}add.php`, {
        price: data.price,
      })
      .then(function (response) {
        console.log(response.data);
        reset();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      });
    //-------------else

    //firstname,lastname,phone,email,password,pltype
  };
  return (
    <div>
      <div className="rounded-sm bg-red-500 border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          {/*           <h3 className="font-medium text-black dark:text-white">
New Category
</h3> */}
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="p-6.5">
              <div className="mb-2">
                <label>ราคาเพิ่มจำนวนคน</label>
                <input
                  type="number"
                  name="price"
                  {...register("price", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.price && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    ป้อนราคาเพิ่มจำนวนคน
                  </div>
                )}
              </div>

              <button
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                disabled={loading}
                type="submit"
              >
                {loading ? <>กรุณาซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddPricePerson;
