import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";
import { facilityUrl, imgUrl } from "../../utils/baseUrl";

const AddFacility = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };
  //   facilities id,name,icon
  const handleOnSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("name", data.name);
    axios.post(`${facilityUrl}add.php`, formData).then(function (response) {
      console.log(response.data);

      // toast.success("Successfully created!");
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      reset();
      setLoading(false); // Stop loading
    });

    /*     Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        reset();
        setLoading(false); */
  };
  return (
    <div>
      <div className="rounded-sm bg-red-500 border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          {/*           <h3 className="font-medium text-black dark:text-white">
        New Category
      </h3> */}
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="p-6.5">
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  ชื่ออุปกรณ์
                </label>
                <input
                  type="text"
                  name="name"
                  {...register("name", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.name && (
                  <div className="my-3 text-normal text-danger italic">
                    ป้อนชื่ออุปกรณ์
                  </div>
                )}
              </div>
              <div className="my-2">
                <label>รูปภาพ</label>
                <div className="flex flex-col items-start">
                  <input type="file" onChange={handleOnChange} />
                </div>
              </div>
              {/*               <button className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray">
            Add New Category
          </button> */}
              <button
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                disabled={loading}
                type="submit"
              >
                {loading ? <>กรุณาซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddFacility;
