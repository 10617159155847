import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";
import {
  roomTypeUrl,
  roomUrl,
  imgUrl,
  pricePersonUrl,
  priceBedUrl,
  checkinUrl,
} from "../../utils/baseUrl";
import moment from "moment";
const RoomCheckOut = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [checkinData, setCheckinData] = useState({});
  const [comment, setComment] = useState("");
  const [paymore, setPaymore] = useState("0");
  // const [staystatus, setStaystatus] = useState(false);
  const [isCheckedStay, setIsCheckedStay] = useState(false);
  const [isCancle, setIsCancle] = useState(false);
  const [priceTypeData, setPriceTypeData] = useState([]);
  const [convertType, setConvertType] = useState("");
  //paymore staystatus

  const handleStayOnChange = () => {
    setIsCheckedStay(!isCheckedStay);
  };
  const handleCancleOnChange = () => {
    setIsCancle(!isCancle);
  };
  function readCheckinData(id) {
    axios
      .get(`${checkinUrl}readcheckinbyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setCheckinData(response.data);
      });
  }
  function readRoomTypeData() {
    axios.get(`${roomTypeUrl}read.php`).then(function (response) {
      console.log(response.data);

      setPriceTypeData(response.data);
    });
  }
  useEffect(() => {
    readCheckinData(id);
    readRoomTypeData();
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isCheckedStay) {
      if (paymore === "") {
        Swal.fire({
          position: "center",
          icon: "question",
          title: "ระบุจำนวนเงินที่ต้องชำระเพิ่มเติมด้วย",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
    }
    setLoading(true);
    axios
      .post(`${checkinUrl}savecheckout.php`, {
        idEdit: id,
        comment,
        isCheckedStay: isCheckedStay,
        priceConvert: convertType,
        paymore,
        isCancle,
      })
      .then(function (response) {
        console.log(response.data);
        readCheckinData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        ///checkout
        window.location.href = "/checkout";
        setLoading(false); // Stop loading
      });
  };
  console.log("Check In: ", checkinData);
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการเช่าห้องพัก
          </h4>
          <div className="max-w-full  overflow-scroll">
            {/* -------- 
//expenses id,billno,expensesdate,description,total,createdAt
*/}
            <table className="w-full table-auto mx-4 mt-8">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[120px] font-medium text-black dark:text-white xl:pl-4">
                    วันที่เข้าพัก
                    <br />
                    วันที่คืนห้องพัก
                  </th>
                  <th className="min-w-[120px] font-medium text-black dark:text-white">
                    รายการเช่าห้องพัก
                    <br />
                    ประเภทห้อง
                  </th>
                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    เพิ่มคน/เตียง
                    <br />
                    เพิ่มผ้าห่ม
                  </th>
                  <th className="min-w-[130px] font-medium text-black dark:text-white">
                    ส่วนลดปกติ/เซลล์
                  </th>
                  <th className="min-w-[120px] font-medium text-black dark:text-white">
                    ยอดทั้งหมด
                    <br />
                    ยอดหักส่วนลด
                  </th>
                  <th className="min-w-[120px] font-medium text-black dark:text-white">
                    วิธีชำระเงิน
                    <br />
                    สถานะ
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-b border-[#eee]  dark:border-strokedark xl:pl-4">
                    <h5 className="font-medium text-black dark:text-white">
                      {moment(checkinData?.checkInDate).format("L")}
                    </h5>
                    <p className="text-sm"> {checkinData?.checkInTime}</p>
                    {/* <p className="text-sm"> {item?.phone}</p> */}
                    <h5 className="font-medium text-black dark:text-white">
                      {moment(checkinData?.checkOutDate).format("L")}
                    </h5>
                    <p className="text-sm"> {checkinData?.checkOutTime}</p>
                  </td>
                  <td className="border-b border-[#eee]  dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.roomno}x{checkinData?.numofday}=
                      {checkinData?.price}
                      <br />
                      {checkinData?.roomtypename}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee]  dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.personplus}/{checkinData?.bedplus}
                    </h5>
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.blanketsmqty}/{checkinData?.blanketsmprice}-
                      {checkinData?.blanketbigqty}/
                      {checkinData?.blanketbigprice}
                      {/* blanketsmqty,blanketsmprice,blanketbigqty,blanketbigprice */}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee]  dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.priceDisNormal}/
                      {checkinData?.priceDisSaleDisSale}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee]  dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.net}
                      <br />
                      {checkinData?.total}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      <p>{checkinData?.paymentMethod}</p>
                      <p className="inline-flex bg-danger bg-opacity-10 py-1 px-3 text-sm font-medium text-warning">
                        {checkinData?.status}
                      </p>
                    </h5>
                  </td>
                </tr>
              </tbody>
            </table>
            {/*             <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white xl:pl-4">
                    วันที่เข้าพัก
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                    วันที่คืนห้อง
                  </th>
                  <th className="min-w-[100px] py-4 px-4 font-medium text-black dark:text-white">
                    ห้องพัก
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                    รวมเงิน
                  </th>
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    เพิ่มคน/เตียง
                  </th>
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    ส่วนลดปกติ/เซลล์
                  </th>
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    ยอดทั้งหมด
                  </th>
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    ยอดหักส่วนลด
                  </th>
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    วิธีชำระเงิน
                  </th>
                  <th className="min-w-[100px] py-4 px-4 font-medium text-black dark:text-white">
                    สถานะ
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-b border-[#eee] py-5 px-4 pl-2 dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {moment(checkinData?.checkInDate).format("L")}
                    </h5>
                    <p className="text-sm"> {checkinData?.checkInTime}</p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {moment(checkinData?.checkOutDate).format("L")}
                    </h5>
                    <p className="text-sm"> {checkinData?.checkOutTime}</p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.roomno}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.price}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      <p className="text-sm">
                        {" "}
                        {checkinData?.personplus}/{checkinData?.bedplus}
                      </p>
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      <p className="text-sm">
                        {" "}
                        {checkinData?.priceDisNormal}/
                        {checkinData?.priceDisSaleDisSale}
                      </p>
                    </h5>
                  </td>

                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.net}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.total}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.paymentMethod}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <div className="flex items-center space-x-3.5">
                      <p className="text-sm"> {checkinData?.status}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table> */}
            <div></div>

            {/* --------  */}
          </div>
          <div>
            <div className="my-8 p-4 flex flex-col gap-4 md:flex-row bg-danger bg-opacity-10 text-lg text-black">
              <div className="mb-4 flex-1">
                <h2 className="mb-2">ลูกค้า:</h2>
                <h5 className="font-medium text-black dark:text-white">
                  {checkinData?.firstname} {checkinData?.lastname}
                </h5>
              </div>
              <div className="mb-4 flex-1">
                <h2 className="mb-2">คืนเงินประกัน:</h2>
                <h5 className="font-medium text-black dark:text-white">
                  {checkinData?.guarantee}
                </h5>
              </div>
              <div className="mb-4 flex-1">
                <h2 className="mb-2">หมายเหตุเพิ่มเติม(กะก่อนหน้า):</h2>
                <h5 className="font-medium text-black dark:text-white">
                  {checkinData?.comments}
                </h5>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="p-6.5">
                <div className="mb-4.5">
                  <h1 className="mb-2.5 block text-black dark:text-white">
                    เช่าห้องพัก(ต่อเวลา)
                  </h1>
                  {/* const [isCheckedPerson, setIsCheckedPerson] = useState(false); */}
                  <div className="flex gap-2 w-full">
                    <input
                      type="checkbox"
                      id="my_id"
                      checked={isCheckedStay}
                      onChange={handleStayOnChange}
                    />
                    <label htmlFor="my_id">
                      {/* เพิ่มคน({isCheckedPerson ? "Yes" : "No"}) */}
                    </label>
                    {isCheckedStay ? (
                      <>
                        <div className="mb-4.5 w-1/2">
                          <label className="mb-2.5 block text-black dark:text-white">
                            เปลี่ยนประเภทห้องพัก
                          </label>
                          <select
                            value={convertType}
                            onChange={(e) => setConvertType(e.target.value)}
                            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                          >
                            <option value=""></option>
                            {/* <option value="0">มองเห็นทั้งหมด</option> */}
                            {priceTypeData.map((item, index) => (
                              <option
                                key={index}
                                value={`${item.id}/${item.price}/${item.title}`}
                              >
                                {item.title}({item.price})
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-4.5 w-1/2">
                          <label className="mb-2.5 block text-black dark:text-white">
                            เพิ่มเงินค่าเช่าห้องพัก
                          </label>
                          <input
                            type="number"
                            value={paymore}
                            onChange={(e) => setPaymore(e.target.value)}
                            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="mb-4.5">
                  <input
                    type="checkbox"
                    id="some_id"
                    checked={isCancle}
                    onChange={handleCancleOnChange}
                    className="mr-2"
                  />
                  <label htmlFor="some_id">
                    ยกเลิกการเช่าห้อง({isCancle ? " ใช่ " : " ไม่ใช่ "})
                  </label>
                </div>
                <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    หมายเหตุเพิ่มเติม
                  </label>
                  <input
                    type="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  />
                </div>
                <button
                  disabled={loading}
                  type="submit"
                  className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                >
                  {loading ? <>กรุณาซักครู่..</> : <>บันทึกและคืนเงินประกัน</>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoomCheckOut;
