import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";
import {
  roomTypeUrl,
  roomUrl,
  imgUrl,
  pricePersonUrl,
  priceBedUrl,
  checkinUrl,
  productUrl,
} from "../../utils/baseUrl";
import { useReactToPrint } from "react-to-print";

// import moment from "moment/min/moment-with-locales";
import moment from "moment";

const PrintSaleBill = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [saleData, setSaleData] = useState({});
  const [saleDetailData, setSaleDetailData] = useState([]);
  const [comment, setComment] = useState("");
  let qty = 0;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //readmainsalebyid.php
  function readSale(id) {
    axios
      .get(`${productUrl}readmainsalebyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setSaleData(response.data);
      });
  }
  //readsaledetailbyid.php
  function readSaleDetail(id) {
    axios
      .get(`${productUrl}readsaledetailbyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setSaleDetailData(response.data);
      });
  }

  useEffect(() => {
    qty = 0;
    readSale(id);
    readSaleDetail(id);
  }, []);

  console.log("Sale: ", saleData);
  console.log("Sale Detail: ", saleDetailData);
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการขายสินค้า
          </h4>
          <div
            className="mt-[100px] max-w-full overflow-x-auto"
            ref={componentRef}
          >
            {/* ----------  */}
            <div className="w-full my-[20px]">
              <div className="flex flex-row justify-between p-[20px]">
                <h5 className="font-medium text-black dark:text-white">
                  เลขห้อง {saleData?.roomno}
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  วันที่ {moment(saleData?.saledate).format("L")}
                </h5>
              </div>
              <div></div>
            </div>
            <table className="w-full table-auto ml-4">
              <thead>
                <tr className="bg-gray-2 text-left">
                  <th className="min-w-[130px] font-medium text-black">
                    ลำดับที่
                  </th>
                  <th className="min-w-[150px] font-medium text-black">
                    สินค้า
                  </th>
                  <th className="min-w-[150px] font-medium text-black">ราคา</th>
                  <th className="min-w-[150px] font-medium text-black">
                    จำนวน
                  </th>
                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    รวมเงิน
                  </th>
                </tr>
              </thead>
              <tbody>
                {saleDetailData.map((item, index) => {
                  qty += Number(item?.qty);
                  return (
                    <tr key={index}>
                      <td className="border-b border-[#eee] pl-2 dark:border-strokedark xl:pl-4">
                        <h5 className="font-medium text-black dark:text-white">
                          {Number(index) + 1}
                        </h5>
                        {/* <p className="text-sm">x</p> */}
                      </td>

                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <p className="font-medium text-black dark:text-white">
                          {item?.productname}
                        </p>
                      </td>

                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {new Intl.NumberFormat().format(item?.price)}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.qty}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {new Intl.NumberFormat().format(item?.subtotal)}
                        </h5>
                      </td>
                    </tr>
                  );
                })}

                <tr className="p-2">
                  <td className="bg-gray-2 text-left dark:bg-meta-4">
                    <h5 className="font-medium text-black dark:text-white">
                      ชำระเงิน
                    </h5>
                  </td>
                  <td className="bg-gray-2 text-left dark:bg-meta-4">
                    <h5 className="font-medium text-black dark:text-white">
                      {saleData?.paymentMethod}
                    </h5>
                  </td>
                  <td className="bg-gray-2 text-left dark:bg-meta-4"></td>
                  <td className="bg-gray-2 text-left dark:bg-meta-4">
                    <h5 className="font-medium text-black dark:text-white">
                      {Number(qty)}
                    </h5>
                  </td>
                  <td className="bg-gray-2 text-left dark:bg-meta-4">
                    <h5 className="font-medium text-black dark:text-white">
                      {new Intl.NumberFormat().format(saleData?.total)}
                    </h5>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* -------  */}
          </div>
          <div className="my-[150px] w-full justify-center rounded">
            <button
              className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
              onClick={handlePrint}
            >
              พิมพ์
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintSaleBill;
