import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";

import { productUrl } from "../../utils/baseUrl";

import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";

import moment from "moment";
moment.locale("th");

const ShowProduct = () => {
  const [saleData, setSaleData] = useState([]);
  let net = 0;
  let qty = 0;

  function readSaleData() {
    axios.get(`${productUrl}readsale.php`).then(function (response) {
      console.log(response.data);
      setSaleData(response.data);
    });
  }

  useEffect(() => {
    net = 0;
    qty = 0;
    readSaleData();
  }, []);
  console.log(saleData);
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการขายสินค้า
          </h4>
          <div className="max-w-full overflow-scroll">
            {/* -------- 
booking idkids,adults,numOfRoom,checkInDate,checkOutDate,total,
,firstName,lastName,phone,email,status,createdAt
*/}
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[150px] font-medium text-black dark:text-white xl:pl-4">
                    วันที่ขาย
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    รายการสินค้า
                  </th>

                  <th className="min-w-[130px] font-medium text-black dark:text-white">
                    ราคา
                  </th>
                  <th className="min-w-[130px] font-medium text-black dark:text-white">
                    จำนวน
                  </th>

                  <th className="font-medium text-black dark:text-white">
                    รวมเงิน
                  </th>
                </tr>
              </thead>
              <tbody>
                {/*               booking id,kids,adults,numOfRoom,checkInDate,checkOutDate,total,
,firstName,lastName,phone,email,status,createdAt */}
                {/* <p className="text-sm"> {item?.checkInTime}</p> */}
                {/* <p className="text-sm"> {item?.phone}</p> */}
                {saleData.map((item, index) => {
                  net += Number(item?.subtotal);
                  qty += Number(item?.qty);
                  // console.log(net);
                  return (
                    <tr key={index}>
                      <td className="border-b border-[#eee] dark:border-strokedark xl:pl-4">
                        <h5 className="font-medium text-black dark:text-white">
                          {moment(item?.saledate).format("L")}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.productname}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.price}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.qty}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee]  dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.subtotal}
                        </h5>
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <h5 className="font-bold text-black dark:text-white">
                      {Number(qty)}
                    </h5>
                  </td>
                  <td>
                    <h5 className="font-bold text-black dark:text-white">
                      {Number(net)}
                    </h5>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* --------  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowProduct;
