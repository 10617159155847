import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";

import {
  roomTypeUrl,
  roomUrl,
  imgUrl,
  pricePersonUrl,
  priceBedUrl,
  checkinUrl,
} from "../../utils/baseUrl";
import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

/* import es from 'date-fns/locale/es';
registerLocale('es', es) */

/* import moment from "moment";
import "moment/locale/th"; */

import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import SimpleTimeInput from "react-simple-time-input";

/* var dd = moment.locale(‘th’)
 this.date = moment().add(543, ‘year’).format(‘LLLL’) */

const NewRoomCheckin = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [roomTypeData, setRoomTypeData] = useState([]);
  const [priceTypeData, setPriceTypeData] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [datas, setDatas] = useState({});
  const [roomTypeId, setRoomTypeId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [size, setSize] = useState("");
  const [maxPerson, setMaxPerson] = useState("");
  const [price, setPrice] = useState("");
  const [priceDisNormal, setPriceDisNormal] = useState("100");
  const [priceDisSaleDisSale, setPriceDisSaleDisSale] = useState("500");
  const [guarantee, setGuarantee] = useState("100");
  const [checkinDate, setCheckinDate] = useState("");
  const [checkoutDate, setCheckoutDate] = useState("");
  const [checkinTime, setCheckinTime] = useState("");
  const [checkoutTime, setCheckoutTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [showRoomDetail, setShowRoomDetail] = useState(false);

  const [isCheckedPerson, setIsCheckedPerson] = useState(false);
  const [isCheckedBed, setIsCheckedBed] = useState(false);
  const [isCheckedDiscount, setIsCheckedDiscount] = useState(false);
  const [isCheckedSaleDiscount, setIsCheckedSaleDiscount] = useState(false);

  const [pricePersonData, setPricePersonData] = useState([]);
  const [priceBedData, setPriceBedData] = useState([]);
  const [pricePerson, setPricePerson] = useState("");
  const [priceBed, setPriceBed] = useState("");

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [cardID, setCardID] = useState("");
  const [payMethod, setPayMethod] = useState("");
  const [convertType, setConvertType] = useState("");
  const [plusPay, setPlusPay] = useState("");

  const [startDate, setStartDate] = useState(new Date());
  /*   const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(), 30), 16)
  ); */
  const [value, onChange] = useState(new Date());
  const [time, setTime] = useState("14:00");

  const valueChanged = (newValue) => {
    setTime(newValue);
    alert(`Time changed to ${newValue}`);
  };

  const date = (value = null) => {
    if (value === null) {
      return null;
    }
    const moment = this.moment(value);
    moment.locale(this.locale);
    return moment;
  };

  function toBuddhistYear(moment, format) {
    var christianYear = moment.format("YYYY");
    var buddhishYear = (parseInt(christianYear) + 543).toString();
    return moment
      .format(
        format
          .replace("YYYY", buddhishYear)
          .replace("YY", buddhishYear.substring(2, 4))
      )
      .replace(christianYear, buddhishYear);
  }

  const format = (date, formatKey) => {
    return this.toBuddhistYear(date, formatKey);
  };

  function readPricePerson() {
    axios.get(`${pricePersonUrl}read.php`).then(function (response) {
      console.log(response.data);
      setPricePersonData(response.data);
    });
  }
  function readPriceBed() {
    axios.get(`${priceBedUrl}read.php`).then(function (response) {
      console.log(response.data);
      setPriceBedData(response.data);
    });
  }

  const handlePersonOnChange = () => {
    setIsCheckedPerson(!isCheckedPerson);
  };
  const handleBedOnChange = () => {
    setIsCheckedBed(!isCheckedBed);
  };
  const handleDiscountOnChange = () => {
    setIsCheckedDiscount(!isCheckedDiscount);
  };
  const handleSaleDiscountOnChange = () => {
    setIsCheckedSaleDiscount(!isCheckedSaleDiscount);
  };
  function readRoomTypeData() {
    axios.get(`${roomTypeUrl}read.php`).then(function (response) {
      console.log(response.data);
      setRoomTypeData(response.data);
      setPriceTypeData(response.data);
    });
  }

  function readRoomData(typeid) {
    axios.get(`${roomUrl}readbytype.php/${typeid}`).then(function (response) {
      console.log(response.data);
      setRoomData(response.data);
    });
  }

  const handleChange = (event) => {
    console.log("Selected: ", event.target.value);
    setSelected(event.target.value);
    readRoomData(event.target.value);
    setShowRoomDetail(false);
  };

  const handleRoomChange = (event) => {
    console.log("Selected Room: ", event.target.value);
    setSelectedRoom(event.target.value);
    setShowRoomDetail(true);
    readData(event.target.value);
    // readRoomData(event.target.value);
  };
  function readData(id) {
    axios.get(`${roomUrl}readbyid.php/${id}`).then(function (response) {
      console.log("MY ROOM: ", response.data);
      setDatas(response.data);
      setRoomTypeId(response.data?.roomTypeId);
      setName(response.data?.name);
      setDescription(response.data?.description);
      setSize(response.data?.size);
      setMaxPerson(response.data?.maxPerson);
      setPrice(response.data?.price);
      ////rooms id,roomTypeId,name,description,size,maxPerson,price,photo,facilityId,createdAt
    });
  }
  //readbytype.php
  useEffect(() => {
    const id = localStorage.getItem("id");
    setUserId(id);
    readRoomTypeData();
    readPricePerson();
    readPriceBed();
  }, []);
  console.log("Room Type: ", roomTypeData);
  console.log("Room: ", roomData);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    /*     alert(pricePerson + "" + priceBed);
      alert(payMethod + " " + convertType); */
    if (payMethod === "") {
      Swal.fire({
        position: "center",
        icon: "question",
        title: "ระบุวิธีชำระเงินด้วย",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    setLoading(true);
    axios
      .post(`${checkinUrl}savecheckin.php`, {
        userId,
        checkinDate,
        checkinTime,
        checkoutDate,
        checkoutTime,
        firstname,
        lastname,
        cardID,
        phone,
        selectedRoom,
        name,
        price,
        guarantee,
        pricePerson,
        priceBed,
        priceDisNormal: isCheckedDiscount ? priceDisNormal : 0,
        priceDisSaleDisSale: isCheckedSaleDiscount ? priceDisSaleDisSale : 0,
        payMethod,
        convertType,
      })
      .then(function (response) {
        console.log(response.data);
        // readData(id);  const [payMethod, setPayMethod] = useState("");
        // const [convertType, setConvertType] = useState("");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        //printbill/:id
        window.location.href = "printbill/" + response.data.lastID;
        // navigate("printbill/" + response.data.lastID);
      });
  };
  return (
    <>
      <div className="overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        {/*         <div className="relative z-20 h-35 p-4">
  <div className="flex items-center justify-end gap-1 md:p-4 rounded-md overflow-hidden">
    <Link to={""} className="">
      <BiSolidGridAlt size={22} />
    </Link>
    <Link to={""} className="">
      <BsPlusCircle size={22} />
    </Link>
  </div>
</div> */}
        {/* ---------  */}
        <div className="p-5">
          {/* <h1>{userId}</h1> */}
          <form onSubmit={handleOnSubmit}>
            <div className="p-6.5">
              <div className="mb-2">
                <label>ประเภทห้องพัก</label>
                <select
                  /*                   value={roomTypeId}
          onChange={(e) => setRoomTypeId(e.target.value)} */
                  value={selected}
                  onChange={handleChange}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                >
                  <option value=""></option>
                  {/* <option value="0">มองเห็นทั้งหมด</option> */}
                  {roomTypeData.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
              {/* ---------roomData roomData.constructor !== Object  */}
              {Object.keys(roomData).length !== 0 ? (
                <>
                  {/* rooms id,roomTypeId,name,description,size,maxPerson,price,photo,facilityId,createdAt */}
                  <div className="mb-2">
                    <label>ห้องพัก</label>
                    <select
                      /*                   value={roomTypeId}
          onChange={(e) => setRoomTypeId(e.target.value)} */
                      value={selectedRoom}
                      onChange={handleRoomChange}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    >
                      <option value=""></option>
                      {/* <option value="0">มองเห็นทั้งหมด</option> */}
                      {roomData.map((item2, index2) => (
                        <option key={index2} value={item2.id}>
                          {item2.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              ) : null}
              {/* ---------  */}
              {Object.keys(datas).length !== 0 ? (
                <>
                  {/* <div className="mb-2">{JSON.stringify(datas)}</div> */}
                  <div>
                    {datas?.photo != null ? (
                      <img
                        src={`${imgUrl}${datas?.photo}`}
                        className="rounded-lg mb-4 md:h-[300px] w-full object-cover"
                      />
                    ) : (
                      "-"
                    )}
                  </div>
                  {/*                   <div className="mb-4.5">
            <label className="mb-2.5 block text-black dark:text-white">
              ประเภทห้องพัก
            </label>
            <select
              value={roomTypeId}
              onChange={(e) => setRoomTypeId(e.target.value)}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            >
              <option value=""></option>
              {roomTypeData.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          </div> */}
                  {/*   const [checkinDate, setCheckinDate] = useState("");
const [checkoutDate, setCheckoutDate] = useState(""); */}
                  {/*     const [checkinTime, setCheckinTime] = useState("");
const [checkoutTime, setCheckoutTime] = useState(""); */}
                  <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
                    <div className="mb-4.5">
                      <label className="mb-2.5 block text-black dark:text-white">
                        วันที่เข้าพัก
                      </label>
                      <DateTimePicker onChange={onChange} value={startDate} />
                      {/*                       <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                      /> */}
                      {/*                       <input
                        type="date"
                        value={checkinDate}
                        onChange={(e) => setCheckinDate(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      /> */}
                    </div>
                    <div className="mb-4.5">
                      <label className="mb-2.5 block text-black dark:text-white">
                        เวลาที่เข้าพัก
                      </label>
                      <SimpleTimeInput
                        value={time}
                        clockMode={24}
                        onValueChange={valueChanged} // called on blur
                      />
                      {/*                       <input
                        type="time"
                        id="time-input"
                        name="time"
                        step="3600"
                        min="00:00"
                        max="23:59"
                        pattern="[0-2][0-9]:[0-5][0-9]"
                      /> */}
                      {/*                       <input
                        type="time"
                        value={checkinTime}
                        onChange={(e) => setCheckinTime(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      /> */}
                    </div>
                    <div className="mb-4.5">
                      <label className="mb-2.5 block text-black dark:text-white">
                        วันที่คืนห้อง
                      </label>
                      <input
                        type="date"
                        value={checkoutDate}
                        onChange={(e) => setCheckoutDate(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                    <div className="mb-4.5">
                      <label className="mb-2.5 block text-black dark:text-white">
                        เวลาที่คืนห้อง
                      </label>
                      <input
                        type="time"
                        value={checkoutTime}
                        onChange={(e) => setCheckoutTime(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                  </div>
                  <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
                    <div className="mb-4.5">
                      <label className="mb-2.5 block text-black dark:text-white">
                        ชื่อห้อง/เลขห้อง
                      </label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full  bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                    {/*                   <div className="mb-4.5">
            <label className="mb-2.5 block text-black dark:text-white">
              รายละเอียด
            </label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div> */}
                    {/*                   <div className="mb-4.5">
            <label className="mb-2.5 block text-black dark:text-white">
              ขนาดห้อง
            </label>
            <input
              type="text"
              value={size}
              onChange={(e) => setSize(e.target.value)}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div> */}
                    <div className="mb-4.5">
                      <label className="mb-2.5 block text-black dark:text-white">
                        จำนวนผู้เข้าพักสูงสุด
                      </label>
                      <input
                        type="text"
                        value={maxPerson}
                        onChange={(e) => setMaxPerson(e.target.value)}
                        className="w-full  bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                    <div className="mb-4.5">
                      <label className="mb-2.5 block text-black dark:text-white">
                        ราคาห้องพัก
                      </label>
                      <input
                        type="text"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        className="w-full  bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                    <div className="mb-4.5">
                      <label className="mb-2.5 block text-black dark:text-white">
                        ค่าประกันห้อง
                      </label>
                      <input
                        type="number"
                        value={guarantee}
                        onChange={(e) => setGuarantee(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>

                    {/* -----------  */}
                    <div className="mb-4.5">
                      <h1 className="mb-2.5 block text-black dark:text-white">
                        เพิ่มคน
                      </h1>
                      {/* const [isCheckedPerson, setIsCheckedPerson] = useState(false); */}
                      <div className="flex gap-2 w-full">
                        <input
                          type="checkbox"
                          id="some_id"
                          checked={isCheckedPerson}
                          onChange={handlePersonOnChange}
                        />
                        <label htmlFor="some_id">
                          {/* เพิ่มคน({isCheckedPerson ? "Yes" : "No"}) */}
                        </label>
                        {isCheckedPerson ? (
                          <div className="mb-2 w-full">
                            <select
                              value={pricePerson}
                              onChange={(e) => setPricePerson(e.target.value)}
                              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                            >
                              {/* <option value=""></option> */}
                              {/* <option value="0">มองเห็นทั้งหมด</option> */}
                              {pricePersonData.map((item, index) => (
                                <option key={index} value={item.price}>
                                  {item.price}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="mb-4.5">
                      {/* const [isCheckedBed, setIsCheckedBed] = useState(false); */}
                      <h1 className="mb-2.5 block text-black dark:text-white">
                        เพิ่มเตียง
                      </h1>
                      <div className="flex gap-2">
                        <input
                          type="checkbox"
                          id="some_id2"
                          checked={isCheckedBed}
                          onChange={handleBedOnChange}
                        />
                        <label htmlFor="some_id2">
                          {/* เพิ่มเตียง({isCheckedBed ? "Yes" : "No"}) */}
                        </label>
                        {isCheckedBed ? (
                          <div className="mb-2 w-full">
                            <select
                              value={priceBed}
                              onChange={(e) => setPriceBed(e.target.value)}
                              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                            >
                              {/* <option value=""></option> */}
                              {/* <option value="0">มองเห็นทั้งหมด</option> */}
                              {priceBedData.map((item, index) => (
                                <option key={index} value={item.price}>
                                  {item.price}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="mb-4.5">
                      <h1 className="mb-2.5 block text-black dark:text-white">
                        ส่วนลดปกติ
                      </h1>
                      <div className="flex gap-2">
                        <input
                          type="checkbox"
                          id="some_id3"
                          checked={isCheckedDiscount}
                          onChange={handleDiscountOnChange}
                        />
                        <label htmlFor="some_id3">
                          {/* Emergency({isCheckedDiscount ? "Yes" : "No"}) */}
                        </label>
                        {isCheckedDiscount ? (
                          <>
                            <input
                              type="number"
                              value={priceDisNormal}
                              onChange={(e) =>
                                setPriceDisNormal(e.target.value)
                              }
                              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                    {/*       const [priceDisNormal, setPriceDisNormal] = useState("0");
const [priceDisSaleDisSale, setPriceDisSaleDisSale] = useState("0");; */}
                    <div className="mb-4.5">
                      <h1 className="mb-2.5 block text-black dark:text-white">
                        ส่วนลดเซลล์
                      </h1>
                      <div className="flex gap-2">
                        <input
                          type="checkbox"
                          id="some_id4"
                          checked={isCheckedSaleDiscount}
                          onChange={handleSaleDiscountOnChange}
                        />
                        <label htmlFor="some_id4">
                          {/* ส่วนลดเซลล์({isCheckedSaleDiscount ? "Yes" : "No"}) */}
                        </label>
                        {isCheckedSaleDiscount ? (
                          <>
                            <input
                              type="number"
                              value={priceDisSaleDisSale}
                              onChange={(e) =>
                                setPriceDisSaleDisSale(e.target.value)
                              }
                              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="mt-4.5 grid gap-4 grid-cols-1 md:grid-cols-4">
                    <div className="mb-4.5">
                      <label className="mb-2.5 block text-black dark:text-white">
                        ชื่อลูกค้า
                      </label>
                      <input
                        type="text"
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                    <div className="mb-4.5">
                      <label className="mb-2.5 block text-black dark:text-white">
                        นามสกุล
                      </label>
                      <input
                        type="text"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                    <div className="mb-4.5">
                      <label className="mb-2.5 block text-black dark:text-white">
                        เลขบัตรประชาชน
                      </label>
                      <input
                        type="text"
                        value={cardID}
                        onChange={(e) => setCardID(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                    <div className="mb-4.5">
                      <label className="mb-2.5 block text-black dark:text-white">
                        โทรศัพท์
                      </label>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                  </div>
                  {/* -----------  */}
                  <div className="mt-4.5 grid gap-4 grid-cols-1 md:grid-cols-4">
                    <div className="mb-4.5">
                      <label className="mb-2.5 block text-black dark:text-white">
                        เปลี่ยนประเภทห้องพัก
                      </label>
                      <select
                        value={convertType}
                        onChange={(e) => setConvertType(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      >
                        <option value=""></option>
                        {/* <option value="0">มองเห็นทั้งหมด</option> */}
                        {priceTypeData.map((item, index) => (
                          <option key={index} value={item.price}>
                            {item.title}({item.price})
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-4.5">
                      <label className="mb-2.5 block text-black dark:text-white">
                        วิธีชำระเงิน
                      </label>
                      <select
                        value={payMethod}
                        onChange={(e) => setPayMethod(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      >
                        <option value=""></option>
                        <option value="เงินสด">เงินสด</option>
                        <option value="เงินโอน">เงินโอน</option>
                      </select>
                    </div>
                  </div>
                  {/* -----------  */}
                </>
              ) : null}
              {/* -----------  */}
              <button
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                disabled={loading}
                type="submit"
              >
                {loading ? <>กรุณาซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
        {/* ---------  */}
      </div>
    </>
  );
};

export default NewRoomCheckin;
