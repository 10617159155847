import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";

import {
  roomTypeUrl,
  roomUrl,
  imgUrl,
  pricePersonUrl,
  priceBedUrl,
  checkinUrl,
} from "../../utils/baseUrl";

import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";
import moment from "moment";

const ViewRoomCheckin = () => {
  const [checkinData, setCheckinData] = useState([]);

  function readCheckinData() {
    axios.get(`${checkinUrl}readcheckin.php`).then(function (response) {
      console.log(response.data);
      setCheckinData(response.data);
    });
  }

  useEffect(() => {
    readCheckinData();
  }, []);
  console.log("All Data: ", checkinData);
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการเช่าห้องพัก
          </h4>
          <div className="max-w-full  overflow-scroll">
            {/* -------- 
    //expenses id,billno,expensesdate,description,total,createdAt
    */}

            <table className="w-full table-auto mx-4 mt-8">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th>Action</th>
                  <th className="min-w-[120px] font-medium text-black dark:text-white xl:pl-4">
                    วันที่เข้าพัก
                    <br />
                    วันที่คืนห้องพัก
                  </th>
                  <th className="min-w-[120px] font-medium text-black dark:text-white">
                    รายการเช่าห้องพัก
                    <br />
                    ประเภทห้อง
                  </th>
                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    เพิ่มคน/เตียง
                    <br />
                    เพิ่มผ้าห่ม
                  </th>
                  <th className="min-w-[130px] font-medium text-black dark:text-white">
                    ส่วนลดปกติ
                    <br />
                    ส่วนลดเซลล์
                  </th>
                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    ยอดทั้งหมด
                    <br />
                    ยอดหักส่วนลด
                  </th>
                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    วิธีชำระเงิน
                    <br />
                    สถานะ
                  </th>
                </tr>
              </thead>
              <tbody>
                {checkinData.map((item, index) => (
                  <React.Fragment key={`cancle_${index}`}>
                    <tr>
                      <td className="border-b border-[#eee] py-5 dark:border-strokedark">
                        <div className="flex items-center">
                          <Link
                            to={`roomcheckout/${item?.id}`}
                            className="rounded bg-primary p-3 font-medium text-gray"
                          >
                            คืนห้อง-{item?.id}
                          </Link>
                        </div>
                      </td>
                      <td className="border-b border-[#eee]  dark:border-strokedark xl:pl-4">
                        <h5 className="font-medium text-black dark:text-white">
                          {moment(item?.checkInDate).format("L")}
                        </h5>
                        <p className="text-sm"> {item?.checkInTime}</p>
                        {/* <p className="text-sm"> {item?.phone}</p> */}
                        <h5 className="font-medium text-black dark:text-white">
                          {moment(item?.checkOutDate).format("L")}
                        </h5>
                        <p className="text-sm"> {item?.checkOutTime}</p>
                      </td>
                      <td className="border-b border-[#eee]  dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.roomno}x{item?.numofday}={item?.price}
                          <br />
                          {item?.roomtypename}
                        </h5>
                        <h5 className="font-medium text-black dark:text-white">
                          *หมายเหตุเพิ่มเติม*
                        </h5>
                        <p className="text-sm">
                          {item?.cancleText ? item?.cancleText : "-"}
                        </p>
                      </td>
                      <td className="border-b border-[#eee]  dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.personplus}/{item?.bedplus}
                        </h5>
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.blanketsmqty}/{item?.blanketsmprice}-
                          {item?.blanketbigqty}/{item?.blanketbigprice}
                          {/* blanketsmqty,blanketsmprice,blanketbigqty,blanketbigprice */}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee]  dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.priceDisNormal}/{item?.priceDisSaleDisSale}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee]  dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.net}
                          <br />
                          {item?.total}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.paymentMethod}
                          <br />
                          {item?.status}
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="7"
                        className="border-b border-[#eee]  dark:border-strokedark xl:pl-4"
                      >
                        <h5 className="font-medium text-black dark:text-white">
                          หมายเหตุ
                        </h5>
                        <p className="text-sm"> {item?.comments}</p>
                        <h5 className="font-medium text-black dark:text-white">
                          สาเหตุยกเลิกการเช่า
                        </h5>
                        <p className="text-sm"> {item?.cancleText}</p>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            {/*             <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[100px] py-4 px-4 font-medium text-black dark:text-white">
                    Action
                  </th>
                  <th className="min-w-[100px] py-4 px-4 font-medium text-black dark:text-white">
                    สถานะ
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white xl:pl-4">
                    วันที่เข้าพัก
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                    วันที่คืนห้อง
                  </th>
                  <th className="min-w-[100px] py-4 px-4 font-medium text-black dark:text-white">
                    ห้องพัก
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                    ราคาห้องพัก
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                    จ่ายเพิ่ม
                  </th>
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    เพิ่มคน/เตียง
                  </th>
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    ส่วนลดปกติ/เซลล์
                  </th>
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    ยอดทั้งหมด
                  </th>
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    ยอดหักส่วนลด
                  </th>
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    วิธีชำระเงิน
                  </th>
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    หมายเหตุเพิ่มเติม
                  </th>
                </tr>
              </thead>
              <tbody>
                {checkinData.map((item, index) => (
                  <tr key={index}>
                    <td className="border-b border-[#eee] py-5 dark:border-strokedark">
                      <div className="flex items-center space-x-3.5">
                        <Link
                          to={`roomcheckout/${item?.id}`}
                          className="rounded bg-primary p-3 font-medium text-gray"
                        >
                          คืนห้อง-{item?.id}
                        </Link>
                      </div>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <div className="flex items-center space-x-3.5">
                        <p className="text-sm"> {item?.status}</p>
                      </div>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 pl-2 dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        {moment(item?.checkInDate).format("L")}
                      </h5>
                      <p className="text-sm"> {item?.checkInTime}</p>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        {moment(item?.checkOutDate).format("L")}
                      </h5>
                      <p className="text-sm"> {item?.checkOutTime}</p>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.roomno}
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.price}
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.paymore}
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        <p className="text-sm">
                          {item?.personplus * item?.numofpersonplus}(
                          {item?.numofpersonplus})/
                          {item?.bedplus * item?.numofbedplus}(
                          {item?.numofbedplus})
                        </p>
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        <p className="text-sm">
                          {" "}
                          {item?.priceDisNormal}/{item?.priceDisSaleDisSale}
                        </p>
                      </h5>
                    </td>

                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.net}
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.total}
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.paymentMethod}
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.cancleText}
                      </h5>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> */}
            {/* --------  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewRoomCheckin;
