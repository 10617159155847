import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";
import {
  roomTypeUrl,
  roomUrl,
  imgUrl,
  pricePersonUrl,
  priceBedUrl,
  checkinUrl,
} from "../../utils/baseUrl";

const HomeReportWithDay = () => {
  const [checkinData, setCheckinData] = useState([]);
  const [checkinMonthData, setCheckinMonthData] = useState([]);
  const [checkinYearData, setCheckinYearData] = useState([]);
  function readCheckinData() {
    axios.get(`${checkinUrl}readcheckinwithday.php`).then(function (response) {
      console.log(response.data);
      setCheckinData(response.data);
    });
  }
  //readcheckinwithmonth.php
  function readCheckinMData() {
    axios
      .get(`${checkinUrl}readcheckinwithmonth.php`)
      .then(function (response) {
        console.log(response.data);
        setCheckinMonthData(response.data);
      });
  }
  //readcheckinwithyear.php
  function readCheckinYData() {
    axios.get(`${checkinUrl}readcheckinwithyear.php`).then(function (response) {
      console.log(response.data);
      setCheckinYearData(response.data);
    });
  }
  useEffect(() => {
    readCheckinData();
    readCheckinMData();
    readCheckinYData();
  }, []);
  console.log("By Day: ", checkinData);
  console.log("By Month: ", checkinData);
  console.log("By Year: ", checkinData);
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการเช่าห้องพัก {new Date().toUTCString()}
          </h4>
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
            {/* ---------  */}
            <div className="mb-4.5">
              <h5 className="font-medium text-black dark:text-white">
                สรุปรายการเช่าห้องพักรายวัน
              </h5>
              <div className="max-w-full overflow-x-auto">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="bg-gray-2 text-left dark:bg-meta-4">
                      <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                        วันที่เข้าพัก
                      </th>
                      <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                        รวมเงิน
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkinData.map((item, index) => (
                      <tr key={index}>
                        <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                          <h5 className="font-medium text-black dark:text-white">
                            {item?.dayofmonth}
                          </h5>
                          {/* <p className="text-sm"> {item?.checkInTime}</p> */}
                          {/* <p className="text-sm"> {item?.phone}</p> */}
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                          <h5 className="font-medium text-black dark:text-white">
                            {item?.total}
                          </h5>
                          {/* <p className="text-sm"> {item?.checkOutTime}</p> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* --------  */}
              </div>
            </div>
            {/* ---------  */}
            {/* ---------  */}
            <div className="mb-4.5">
              <h5 className="font-medium text-black dark:text-white">
                สรุปรายการเช่าห้องพักรายเดือน
              </h5>
              <div className="max-w-full overflow-x-auto">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="bg-gray-2 text-left dark:bg-meta-4">
                      <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                        เดือน
                      </th>
                      <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                        รวมเงิน
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkinMonthData.map((item, index) => (
                      <tr key={index}>
                        <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                          <h5 className="font-medium text-black dark:text-white">
                            {item?.dayofmonth}
                          </h5>
                          {/* <p className="text-sm"> {item?.checkInTime}</p> */}
                          {/* <p className="text-sm"> {item?.phone}</p> */}
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                          <h5 className="font-medium text-black dark:text-white">
                            {item?.total}
                          </h5>
                          {/* <p className="text-sm"> {item?.checkOutTime}</p> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* --------  */}
              </div>
            </div>
            {/* ---------  */}
            {/* ---------  */}
            <div className="mb-4.5">
              <h5 className="font-medium text-black dark:text-white">
                สรุปรายการเช่าห้องพักรายปี
              </h5>
              <div className="max-w-full overflow-x-auto">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="bg-gray-2 text-left dark:bg-meta-4">
                      <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                        ปี
                      </th>
                      <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                        รวมเงิน
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkinYearData.map((item, index) => (
                      <tr key={index}>
                        <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                          <h5 className="font-medium text-black dark:text-white">
                            {item?.dayofmonth}
                          </h5>
                          {/* <p className="text-sm"> {item?.checkInTime}</p> */}
                          {/* <p className="text-sm"> {item?.phone}</p> */}
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                          <h5 className="font-medium text-black dark:text-white">
                            {item?.total}
                          </h5>
                          {/* <p className="text-sm"> {item?.checkOutTime}</p> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* --------  */}
              </div>
            </div>
            {/* ---------  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeReportWithDay;
