import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";

import { bookingUrl } from "../../utils/baseUrl";

import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";

import moment from "moment";
moment.locale("th");

const ViewBooking = () => {
  const [checkinData, setCheckinData] = useState([]);

  function readCheckinData() {
    axios.get(`${bookingUrl}readbyday.php`).then(function (response) {
      console.log(response.data);
      setCheckinData(response.data);
    });
  }

  useEffect(() => {
    readCheckinData();
  }, []);
  console.log(checkinData);
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการจองห้องพัก
          </h4>
          <div className="max-w-full overflow-scroll">
            {/* -------- 
booking idkids,adults,numOfRoom,checkInDate,checkOutDate,total,
,firstName,lastName,phone,email,status,createdAt
*/}
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    ลูกค้า
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    วันที่เข้าพัก
                    <br />
                    วันที่คืนห้องพัก
                  </th>

                  <th className="min-w-[130px] font-medium text-black dark:text-white">
                    จำนวนผู้เข้าพัก
                    <br />
                    จำนวนห้องพัก
                  </th>
                  <th className="min-w-[130px] font-medium text-black dark:text-white">
                    มัดจำ
                  </th>

                  <th className="font-medium text-black dark:text-white">
                    สถานะ
                  </th>
                  <th className="font-medium text-black dark:text-white">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {/*               booking id,kids,adults,numOfRoom,checkInDate,checkOutDate,total,
,firstName,lastName,phone,email,status,createdAt */}

                {checkinData.map((item, index) => (
                  <tr key={index}>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.firstName} {item?.lastName}
                      </h5>
                      <p className="text-sm"> {item?.phone}</p>
                      <p className="text-sm"> {item?.email}</p>
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        {moment(item?.checkInDate).format("L")}
                      </h5>
                      <h5 className="font-medium text-black dark:text-white">
                        {moment(item?.checkOutDate).format("L")}
                      </h5>
                      {/* <p className="text-sm"> {item?.checkInTime}</p> */}
                      {/* <p className="text-sm"> {item?.phone}</p> */}
                    </td>

                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        เด็ก:{item?.kids}/ผู้ใหญ่:{item?.adults}
                      </h5>
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.numOfRoom}
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.deposit}
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.status}
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <div className="flex items-center space-x-3.5">
                        {/* <p className="text-sm"> {item?.status}</p> */}
                        {/* {item?.guarantee}                                            <button
              onClick={() => deleteData(item?.id)}
              className="hover:text-primary"
            >
              <IoTrashOutline size={22} />
            </button> */}
                        <Link
                          to={`confirmdeposit/${item?.id}`}
                          className="rounded bg-primary p-3 font-medium text-gray"
                        >
                          บันทึกเงินมัดจำ
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* --------  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewBooking;
