import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

import Swal from "sweetalert2";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

import { employeeUrl, imgUrl } from "../../utils/baseUrl";
const ViewEmployee = () => {
  const [loading, setLoading] = useState(false);
  const [empData, setEmpData] = useState([]);

  function readEmpData() {
    axios.get(`${employeeUrl}read.php`).then(function (response) {
      console.log(response.data);
      setEmpData(response.data);
    });
  }

  useEffect(() => {
    readEmpData();
  }, []);
  console.log(empData);

  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      console.log(id);
      if (result.isConfirmed) {
        axios
          .delete(`${employeeUrl}delete.php/${id}`)
          .then(function (response) {
            console.log(response.data);
            readEmpData();
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            ข้อมูลพนักงาน
          </h4>
          <div className="max-w-full overflow-scroll">
            {/* --------  */}
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[220px] font-medium text-black dark:text-white xl:pl-2">
                    ข้อมูลพนักงาน
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    ตำแหน่ง/เงินเดือน(บาท)
                  </th>
                  <th className="font-medium text-black dark:text-white">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {empData.map((item, index) => (
                  <tr key={index}>
                    <td className="border-b border-[#eee] dark:border-strokedark xl:pl-2">
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.firstName} {item?.lastName}
                      </h5>
                      <p className="text-sm"> {item?.address}</p>
                      <p className="text-sm"> {item?.phone}</p>
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      {item?.positions}/{item?.salary}
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <div className="flex items-center space-x-3.5">
                        <button
                          onClick={() => deleteData(item?.id)}
                          className="hover:text-primary"
                        >
                          <IoTrashOutline size={22} />
                        </button>
                        <Link to={`editemployee/${item?.id}`} className="">
                          <HiMiniPencilSquare size={22} />
                        </Link>
                        {/*                         <Link to={`addsalary/${item?.id}`} className="">
                          บันทึกเงินเดือน
                        </Link> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* --------  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewEmployee;
