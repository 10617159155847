import React, { useState, useEffect, useRef } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";

import {
  roomTypeUrl,
  roomUrl,
  imgUrl,
  pricePersonUrl,
  priceBedUrl,
  checkinUrl,
  bookingUrl,
  productUrl,
} from "../../utils/baseUrl";

import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from "react-to-print";
import moment from "moment/min/moment-with-locales";
moment.locale("th");

const ReportSale = () => {
  var date = new Date(new Date());
  // date.setDate(date.getDate() + 1);
  const [startDate, setStartDate] = useState(new Date());
  const [checkinDate, setCheckinDate] = useState("");
  // const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(date.setDate(date.getDate() + 1));
  const [checkinData, setCheckinData] = useState([]);
  const [checkinWithDateData, setCheckinWithDateData] = useState([]);
  const [saleData, setSaleData] = useState([]);
  const [loading, setLoading] = useState(false);
  let total = 0;

  const [isLoading, setIsLoading] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    searchCompany(searchTerm);
    setSearchItem(searchTerm);
  };

  function searchCompany(txtOcc) {
    setIsLoading(true);
    total = 0;
    axios
      .post(`${productUrl}readsalebetweenday.php`, {
        beginDate: txtOcc,
      })
      .then(function (response) {
        console.log("Search---------");
        const data = response.data;
        console.log("Type: ", typeof data);
        console.log(response?.data);
        console.log("---------Search");
        // setMemberData(response?.data);
        setSaleData(response.data);
        // memberID
        // readLogo(response?.data.memberID);
        setIsLoading(false);
      });
  }
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function readCheckinData() {
    axios
      .get(`${checkinUrl}readcheckinbetweenbyday.php`)
      .then(function (response) {
        console.log(response.data);
        setCheckinData(response.data);
      });
  }

  function readCheckinWithDayData() {
    axios
      .post(`${checkinUrl}readcheckinbetweenbyday.php`, {})
      .then(function (response) {
        console.log(response.data);
        setCheckinWithDateData(response.data);
      });
  }
  console.log(checkinData);

  const searchData = (e) => {
    e.preventDefault();
    // alert(checkinDate);
    total = 0;
    setLoading(true);
    axios
      .post(`${productUrl}readsalebetweenday.php`, {
        beginDate: checkinDate,
      })
      .then(function (response) {
        console.log(response.data);
        setSaleData(response.data);
        setLoading(false);
      });
    //-------------
  };
  console.log(checkinWithDateData);
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการขายสินค้า
          </h4>
          <div className="relative z-20 p-4">
            <div className="flex items-center justify-center gap-1 md:p-4 rounded-md w-full">
              <div className="flex  items-center gap-1 w-full">
                {/* <span>ระบุวันที่:{checkinData} </span> */}

                <input
                  type="date"
                  className="w-full border py-2 text-grey-darkest"
                  value={searchItem}
                  onChange={handleInputChange}
                  placeholder={`ค้นหา...`}
                />
                <button
                  className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
                  onClick={handlePrint}
                >
                  พิมพ์
                </button>
              </div>
            </div>
          </div>
          <div ref={componentRef} className="w-full  overflow-scroll">
            {/* -------- 
booking idkids,adults,numOfRoom,checkInDate,checkOutDate,total,
,firstName,lastName,phone,email,status,createdAt
*/}
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[150px] font-medium text-black dark:text-white xl:pl-4">
                    วันที่ขาย
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    รายการสินค้า
                  </th>

                  <th className="min-w-[130px] font-medium text-black dark:text-white">
                    ราคา
                  </th>
                  <th className="min-w-[130px] font-medium text-black dark:text-white">
                    จำนวน
                  </th>

                  <th className="py-4 px-4 font-medium text-black dark:text-white">
                    รวมเงิน
                  </th>
                </tr>
              </thead>
              <tbody>
                {/*               booking id,kids,adults,numOfRoom,checkInDate,checkOutDate,total,
,firstName,lastName,phone,email,status,createdAt */}

                {saleData.map((item, index) => {
                  total = Number(total) + Number(item?.subtotal);
                  return (
                    <tr key={index}>
                      <td className="border-b border-[#eee] dark:border-strokedark xl:pl-4">
                        <h5 className="font-medium text-black dark:text-white">
                          {moment(item?.saledate).format("L")}
                        </h5>
                      </td>

                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.productname}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.price}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.qty}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee]  dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {item?.subtotal}
                        </h5>
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <h5 className="font-medium text-black dark:text-white">
                      รวมเงิน{" "}
                    </h5>
                  </td>
                  <td>
                    <h5 className="font-medium text-black dark:text-white">
                      {total} บาท
                    </h5>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* --------  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportSale;
