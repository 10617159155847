import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";

import { roomTypeUrl, roomUrl, imgUrl } from "../../utils/baseUrl";

const AddRoom = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      roomTypeId: "",
      name: "",
      description: "",
      size: "",
      maxPerson: "",
      price: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [roomTypeData, setRoomTypeData] = useState([]);

  function readRoomTypeData() {
    axios.get(`${roomTypeUrl}read.php`).then(function (response) {
      console.log(response.data);
      setRoomTypeData(response.data);
    });
  }

  useEffect(() => {
    readRoomTypeData();
  }, []);

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };
  //   rooms id,roomTypeId,name,description,size,maxPerson,price,photo,facilityId,createdAt
  const handleOnSubmit = (data) => {
    setLoading(true);
    /*     roomTypeId: "",
    name: "",
    description: "",
    size: "",
    maxPerson: "",
    price: "", */
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("roomTypeId", data.roomTypeId);
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("size", data.size);
    formData.append("maxPerson", data.maxPerson);
    formData.append("price", data.price);
    axios.post(`${roomUrl}add.php`, formData).then(function (response) {
      console.log(response.data);

      // toast.success("Successfully created!");
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      reset();
      setLoading(false); // Stop loading
    });
  };

  return (
    <div>
      <div className="rounded-sm bg-red-500 border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          {/*           <h3 className="font-medium text-black dark:text-white">
        New Category
      </h3> */}
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="p-6.5">
              {/*           <div className="mb-4.5">
            <label className="mb-2.5 block text-black dark:text-white">
              ประเภทห้องพัก
            </label>
            <input
              type="text"
              name="title"
              {...register("title", {
                required: true,
              })}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
            {errors.title && (
              <div className="my-3 text-normal text-danger italic">
                ป้อนประเภทห้องพัก
              </div>
            )}
          </div> */}
              <div className="mb-2">
                <label>ประเภทห้องพัก</label>
                <select
                  {...register("roomTypeId", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                >
                  <option value=""></option>
                  {/* <option value="0">มองเห็นทั้งหมด</option> */}
                  {roomTypeData.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {errors.roomTypeId && (
                  <div className="my-3 text-normal text-danger italic">
                    ป้อนประเภทห้องพัก
                  </div>
                )}
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  ชื่อห้อง/เลขห้อง
                </label>
                <input
                  type="text"
                  name="name"
                  {...register("name", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.name && (
                  <div className="my-3 text-normal text-danger italic">
                    ป้อนชื่อชื่อห้อง/เลขห้อง
                  </div>
                )}
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  รายละเอียด
                </label>
                <input
                  type="text"
                  name="description"
                  {...register("description", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.description && (
                  <div className="my-3 text-normal text-danger italic">
                    ป้อนรายละเอียด
                  </div>
                )}
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  ขนาดห้อง
                </label>
                <input
                  type="text"
                  name="size"
                  {...register("size", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.size && (
                  <div className="my-3 text-normal text-danger italic">
                    ป้อนขนาดห้อง
                  </div>
                )}
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  จำนวนผู้เข้าพักสูงสุด
                </label>
                <input
                  type="number"
                  name="maxPerson"
                  {...register("maxPerson", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.maxPerson && (
                  <div className="my-3 text-normal text-danger italic">
                    ป้อนจำนวนผู้เข้าพักสูงสุด
                  </div>
                )}
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  ราคาห้องพัก
                </label>
                <input
                  type="number"
                  name="price"
                  {...register("price", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.price && (
                  <div className="my-3 text-normal text-danger italic">
                    ป้อนราคาห้องพัก
                  </div>
                )}
              </div>
              <div className="my-2">
                <label>รูปภาพ</label>
                <div className="flex flex-col items-start">
                  <input type="file" onChange={handleOnChange} />
                </div>
              </div>
              {/*               <button className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray">
            Add New Category
          </button> */}
              <button
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                disabled={loading}
                type="submit"
              >
                {loading ? <>กรุณาซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddRoom;
