import React from "react";
import moment from "moment/min/moment-with-locales";
moment.locale("th");
const SumIncome = ({ sumcheckin }) => {
  let net = 0;
  console.log("Ck In: ", sumcheckin);
  console.log("Ck In len: ", sumcheckin[0].length);
  return (
    <>
      <div>
        <table className=" min-w-[220px] w-full table-auto mx-4 mt-8">
          <thead>
            <tr className="bg-gray-2 text-left dark:bg-meta-4">
              <th className="border min-w-[50px] font-medium text-black">
                เดือน
              </th>
              <th className="border min-w-[70px] font-medium text-black">
                ว/ด/ป
              </th>
              <th className="border min-w-[100px] font-medium text-black">
                ค่าห้อง
              </th>
            </tr>
          </thead>
          <tbody>
            {sumcheckin[0].map((item, idx) => {
              net += Number(item?.total);
              return (
                <tr key={idx}>
                  <td className="border">{item?.month}</td>
                  <td className="border">{moment(item?.date).format("L")}</td>
                  <td className="border">{item?.total}</td>
                </tr>
              );
            })}
            <tr>
              <td></td>
              <td></td>
              <td className="border bg-gray">{net ? net : 0}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SumIncome;
