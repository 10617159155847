import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

import Swal from "sweetalert2";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";
// import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import { productUrl } from "../../utils/baseUrl";

const SaleProduct = () => {
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [services, setServices] = useState([]);
  const [userId, setUserId] = useState("");
  /*   const [serviceName, setServiceName] = useState("");
  const [serviceQty, setServiceQty] = useState("");
  const [servicePrice, setServicePrice] = useState(""); */
  const [totalPrice, setTotalPrice] = useState("");
  const [qty, setQty] = useState("1");
  const [selected, setSelected] = useState(null);
  const [visible, setVisible] = useState(false);
  const [payMethod, setPayMethod] = useState("เงินสด");
  const [roomno, setRoomno] = useState("");

  const onOptionChange = (e) => {
    setPayMethod(e.target.value);
  };

  function readProductData() {
    //readstock.php
    axios.get(`${productUrl}readstock.php`).then(function (response) {
      console.log(response.data);
      setProductData(response.data);
    });
  }

  useEffect(() => {
    var id = localStorage.getItem("id");
    setUserId(id);
    readProductData();
    setTotalPrice("0");
    setServices([]);
  }, []);

  const addServices = (item) => {
    // console.log('Services');
    // alert(JSON.stringify(item));

    //{id: 1, topic: 'topic 1', qty: '0', price: '0'},
    /*       console.log(serviceName);
      console.log(serviceQty);
      console.log(servicePrice); */
    setLoading(true);
    const newService = {
      id: item?.id,
      topic: item?.productName,
      qty: qty,
      price: item?.price,
      subtotal: Number(qty * item?.price),
    };
    // alert(newService);
    setTotalPrice((prev) => Number(prev) + Number(qty * item?.price));
    setServices([...services, newService]);
    setQty("1");
    setLoading(false);
    /*       setServiceName("");
      setServiceQty("");
      setServicePrice(""); */
  };

  const clearAlldata = () => {
    /*     Alert.alert("ยืนยัน", "ต้องการลบข้อมูลทั้งหมด?", [
      {
        text: "Yes",
        onPress: () => {
          setServices([]);
          setTotalPrice("0");
        },
      },
      {
        text: "No",
      },
    ]); */
    Swal.fire({
      title: "ยืนยัน",
      text: "ต้องการลบข้อมูลทั้งหมด?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
    }).then((result) => {
      //   console.log(id);
      if (result.isConfirmed) {
        setServices([]);
        setTotalPrice("0");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "ลบข้อมูลนี้แล้ว",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  const deleteService = (serviceId, qty, price) => {
    /*     Alert.alert("ยืนยัน", "ต้องการลบข้อมูลนี้?", [
      {
        text: "Yes",
        onPress: () => {
          const newItem = services.filter((item) => item.id != serviceId);
          setTotalPrice((prev) => Number(prev) - qty * price);
          setServices(newItem);
        },
      },
      {
        text: "No",
      },
    ]); */
    Swal.fire({
      title: "ยืนยัน",
      text: "ต้องการลบข้อมูลนี้?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
    }).then((result) => {
      //   console.log(id);
      if (result.isConfirmed) {
        const newItem = services.filter((item) => item.id != serviceId);
        setTotalPrice((prev) => Number(prev) - Number(qty * price));
        setServices(newItem);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "ลบข้อมูลนี้แล้ว",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  const onClose = () => {
    setVisible(false);
  };

  const onSend = async () => {
    // alert("save");
    setLoading(true);
    await axios
      .post(`${productUrl}addsale.php`, { services, userId, payMethod, roomno })
      .then((response) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "บันทึกข้อมูลเรียบร้อยแล้ว",
          showConfirmButton: false,
          timer: 1500,
        });
        setTotalPrice("0");
        setRoomno("");
        setServices([]);
        setLoading(false);
        //printbill/:id
        window.location.href = "printsalebill/" + response.data.lastID;
      });
  };

  console.log(productData);
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          {/* <p>{JSON.stringify(services)}</p> */}
          <div className="max-w-full  overflow-scroll">
            <div>
              {/* ----------  */}
              {services.length > 0 ? (
                <>
                  <table className="w-full table-auto">
                    <thead>
                      <tr className="bg-gray-2 text-left dark:bg-meta-4">
                        <th>รายการ</th>
                        <th>ราคา(บาท)</th>
                        <th>จำนวน</th>
                        <th>รวมเงิน</th>
                        <th>ลบ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {services.map((pp, i) => {
                        return (
                          <tr key={`${pp?.topic}_${i}`}>
                            <td>{pp?.topic}</td>
                            <td>{pp?.price}</td>
                            <td>{pp?.qty}</td>
                            <td>{pp?.subtotal}</td>
                            <td>
                              <button
                                type="button"
                                className="rounded bg-warning py-1 px-4 font-medium text-gray"
                                onClick={() =>
                                  deleteService(pp?.id, pp?.qty, pp?.price)
                                }
                              >
                                x
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <span className="font-bold">รวมเงินทั้งหมด</span>
                        </td>
                        <td>
                          <span className="font-bold">{totalPrice}</span>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <span className="font-bold">เลขห้อง: {roomno}</span>
                        </td>
                        <td>
                          <input
                            type="number"
                            value={roomno}
                            onChange={(e) => setRoomno(e.target.value)}
                            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <span className="font-bold">
                            วิธีชำระเงิน ({payMethod})
                          </span>
                        </td>
                        <td>
                          <div className="flex gap-4">
                            <div className="flex items-center gap-2">
                              <label
                                htmlFor="payMethod1"
                                className="cursor-pointer"
                              >
                                <input
                                  type="radio"
                                  name="payMethod"
                                  value="เงินสด"
                                  id="payMethod1"
                                  onChange={onOptionChange}
                                  checked={
                                    payMethod === "เงินสด" ? true : false
                                  }
                                />
                                เงินสด
                              </label>
                            </div>
                            <div className="flex items-center gap-2">
                              <label
                                htmlFor="payMethod2"
                                className="cursor-pointer"
                              >
                                <input
                                  type="radio"
                                  name="payMethod"
                                  value="เงินโอน"
                                  id="payMethod2"
                                  onChange={onOptionChange}
                                  checked={
                                    payMethod === "เงินโอน" ? true : false
                                  }
                                />
                                เงินโอน
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="flex justify-center gap-4 mt-6">
                    <button
                      type="button"
                      onClick={() => clearAlldata()}
                      className="flex w-[200px] justify-center rounded bg-danger p-3 font-medium text-gray"
                    >
                      ลบทั้งหมด
                    </button>
                    <button
                      type="button"
                      onClick={() => onSend()}
                      className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
                    >
                      บันทึกการขาย
                    </button>
                  </div>
                </>
              ) : null}
            </div>
            <h4 className="my-6 text-xl font-semibold text-black dark:text-white">
              รายการสินค้า
            </h4>
            {/* --------  */}
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[220px] font-medium text-black dark:text-white xl:pl-4">
                    ชื่อสินค้า
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    สต็อกสินค้า
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    ราคา(บาท)
                  </th>
                  <th className="font-medium text-black dark:text-white">
                    จำนวนสินค้า
                  </th>
                  <th className="font-medium text-black dark:text-white">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {productData.map((item, index) => (
                  <tr key={index}>
                    <td className="border-b border-[#eee] dark:border-strokedark xl:pl-4">
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.productName}
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      {item?.stock}
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      {item?.price}
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <input
                        type="number"
                        name="qty"
                        value={qty}
                        min={1}
                        max={item?.stock}
                        onChange={(e) => setQty(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </td>
                    {/*                     <td className="border-b border-[#eee] dark:border-strokedark">
                      <div className="flex items-center space-x-3.5">
                        <div className="mb-2">
                          <label>สต็อกสินค้า</label>
                          <input
                            type="number"
                            name="stock"
                            value={qty}
                            min={1}
                            max={item?.stock}
                            onChange={(e) => setQty(e.target.value)}
                            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                          />
                        </div>
                      </div>
                    </td>
                    addServices
                    */}
                    <td>
                      <button
                        disabled={loading}
                        onClick={() => addServices(item)}
                        type="buttom"
                        className="flex w-full justify-center rounded bg-primary p-2 font-medium text-gray"
                      >
                        {loading ? <>กรุณาซักครู่..</> : <>หยิบใล่ตระกร้า</>}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* --------  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SaleProduct;
