import React, { useState, useEffect, useContext } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";
// import { roomTypeUrl, roomUrl, imgUrl } from "../../utils/baseUrl";
import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

/* import es from 'date-fns/locale/es';
registerLocale('es', es) */

/* import moment from "moment";
import "moment/locale/th"; */

import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import SimpleTimeInput from "react-simple-time-input";

import {
  roomTypeUrl,
  roomUrl,
  imgUrl,
  pricePersonUrl,
  priceBedUrl,
  checkinUrl,
} from "../../utils/baseUrl";

import { RentContext } from "../../context/RentContext";

const SearchRoom = () => {
  const {
    adults,
    setAdults,
    convertType,
    setConvertType,
    payMethod,
    setPayMethod,
    startDate,
    setStartDate,
    roomBooking,
    setRoomBooking,
    handleClick,
  } = useContext(RentContext);
  // onClick={() => setAdults(li.name)}
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [roomTypeData, setRoomTypeData] = useState([]);
  const [roomData, setRoomData] = useState([]);
  // const [roomBooking, setRoomBooking] = useState({});
  const [checkinDate, setCheckinDate] = useState("");
  // const [checkoutDate, setCheckoutDate] = useState("");
  const [checkinTime, setCheckinTime] = useState("");
  // const [checkoutTime, setCheckoutTime] = useState("");
  // const [convertType, setConvertType] = useState("");
  const [plusPay, setPlusPay] = useState("");
  // const [payMethod, setPayMethod] = useState("");
  // const [startDate, setStartDate] = useState(new Date());
  const [value, onChange] = useState(new Date());
  const [time, setTime] = useState("14:00");

  function readRoomTypeData() {
    axios.get(`${roomTypeUrl}read.php`).then(function (response) {
      console.log(response.data);
      setRoomTypeData(response.data);
    });
  }
  function readRoomData() {
    axios.get(`${roomUrl}read.php`).then(function (response) {
      console.log(response.data);
      setRoomData(response.data);
    });
  }

  useEffect(() => {
    readRoomTypeData();
    readRoomData();
  }, []);

  const handleConfirmClick = (e, item) => {
    e.preventDefault();
    /*     setTotal(Number(adults[0]) + Number(kids[0]));
    console.log("Total IN Click: ", total);

    const newRooms = roomData.filter((room) => {
      return total <= room.maxPerson;
    });
    setRooms(newRooms); */
    setRoomBooking(item);
    console.log("Current: ", item);
    /*     console.log(payMethod);
    console.log(startDate);
    console.log(convertType); */
    console.log("Current State: ", roomBooking);
    // alert(JSON.stringify(roomBooking));
    //roomdetail
    // navigate("roomdetail");
    Swal.fire({
      title: "แจ้งเตือน",
      text: "กรุณายืนยันการ เช่าห้อง " + item.name + " นี้",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      // console.log(id);
      if (result.isConfirmed) {
        // window.location.href = "roomdetail";
        navigate("/checkinroom/roomdetail");
      }
    });
  };

  return (
    <div className="h-screen overflow-y-auto">
      <div>
        <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
          รายการห้องพัก
        </h4>
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 xl:grid-cols-4">
          {roomData.map((item, index) => (
            <div
              key={index}
              className="rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark"
            >
              {item.photo !== "" ? (
                <img
                  src={`${imgUrl}${item.photo}`}
                  className="rounded-lg object-cover w-full h-[100px] mb-2"
                />
              ) : (
                "-"
              )}
              <h5 className="font-medium text-black dark:text-white">
                เลขห้อง {item?.name}
              </h5>
              <p className="text-sm"> {item?.description}</p>
              <p className="text-sm">ขนาดห้อง {item?.size}</p>
              <p className="text-sm">จำนวนผู้เข้าพัก {item?.maxPerson}</p>
              <p className="text-md">ราคา {item?.price} บาท</p>
              <div className="mt-2">
                {/* <div className="mb-4.5 w-full">
                  <label className="mb-2.5 block text-black dark:text-white">
                    วันที่เข้าพัก
                  </label>
                  <DateTimePicker
                    onChange={(e) => setStartDate(e.target.value)}
                    value={startDate}
                    className="w-full"
                  />
                </div> */}
                {/*                 <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    ประเภทห้องพัก
                  </label>
                  <select
                    value={convertType}
                    onChange={(e) => setConvertType(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  >
                    <option value=""></option>

                    {roomTypeData.map((item, index) => (
                      <option key={index} value={item.price}>
                        {item.title}({item.price})
                      </option>
                    ))}
                  </select>
                </div> */}
                {/*                 <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    วิธีชำระเงิน
                  </label>
                  <select
                    value={payMethod}
                    onChange={(e) => setPayMethod(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  >
                    <option value=""></option>
                    <option value="เงินสด">เงินสด</option>
                    <option value="เงินโอน">เงินโอน</option>
                  </select>
                </div> */}
                <button
                  className="flex w-full justify-center rounded bg-primary p-1 font-medium text-gray"
                  disabled={loading}
                  type="button"
                  onClick={(e) => handleConfirmClick(e, item)}
                >
                  {loading ? <>กรุณาซักครู่..</> : <>ดำเนินการ</>}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchRoom;
