import React, { useState, useEffect, useRef } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";

import {
  roomTypeUrl,
  roomUrl,
  imgUrl,
  pricePersonUrl,
  priceBedUrl,
  checkinUrl,
} from "../../utils/baseUrl";

import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from "react-to-print";
import moment from "moment/min/moment-with-locales";
moment.locale("th");

const RentCancleByDay = () => {
  var date = new Date(new Date());
  // date.setDate(date.getDate() + 1);
  const [startDate, setStartDate] = useState(new Date());
  const [checkinDate, setCheckinDate] = useState("");
  // const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(date.setDate(date.getDate() + 1));
  const [checkinData, setCheckinData] = useState([]);
  const [checkinWithDateData, setCheckinWithDateData] = useState([]);
  const [loading, setLoading] = useState(false);
  let total = 0;
  let net = 0;

  const [isLoading, setIsLoading] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    searchCompany(searchTerm);
    setSearchItem(searchTerm);
  };

  function searchCompany(txtOcc) {
    setIsLoading(true);
    total = 0;
    net = 0;
    axios
      .post(`${checkinUrl}readcanclebetweenbyday.php`, {
        beginDate: txtOcc,
      })
      .then(function (response) {
        console.log("Search---------");
        const data = response.data;
        console.log("Type: ", typeof data);
        console.log(response?.data);
        console.log("---------Search");
        // setMemberData(response?.data);
        setCheckinWithDateData(response.data);
        // memberID
        // readLogo(response?.data.memberID);
        setIsLoading(false);
      });
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function readCheckinData() {
    axios
      .get(`${checkinUrl}readcheckinbetweenbyday.php`)
      .then(function (response) {
        console.log(response.data);
        setCheckinData(response.data);
      });
  }

  function readCheckinWithDayData() {
    axios
      .post(`${checkinUrl}readcheckinbetweenbyday.php`, {})
      .then(function (response) {
        console.log(response.data);
        setCheckinWithDateData(response.data);
      });
  }
  console.log(checkinData);

  const searchData = (e) => {
    e.preventDefault();
    // alert(checkinDate);
    total = 0;
    net = 0;
    setLoading(true);
    //readcheckinbetweenbyday.php
    //readcheckoutbetweenbyday.php
    axios
      .post(`${checkinUrl}readcanclebetweenbyday.php`, {
        beginDate: checkinDate,
      })
      .then(function (response) {
        console.log(response.data);
        setCheckinWithDateData(response.data);
        setLoading(false);
      });
    //-------------
    /*     axios
          .post(`${employeeUrl}add.php`, {
            firstname: data.firstname,
            lastname: data.lastname,
            address: data.address,
            phone: data.phone,
            positions: data.positions,
            salary: data.salary,
          })
          .then(function (response) {
            console.log(response.data);
            reset();
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            });
            setLoading(false);
          }); */
  };

  console.log("Day: ", checkinWithDateData);
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการยกเลิกเช่าห้องพัก
          </h4>
          <div className="relative z-20 p-4">
            <div className="flex items-center justify-center gap-1 md:p-4 rounded-md w-full">
              <div className="flex  items-center gap-1 w-full">
                {/* <span>ระบุวันที่:{checkinData} </span> */}

                <input
                  type="date"
                  className="w-full border py-2 text-grey-darkest"
                  value={searchItem}
                  onChange={handleInputChange}
                  placeholder={`ค้นหา`}
                />
                <button
                  className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
                  onClick={handlePrint}
                >
                  พิมพ์
                </button>
              </div>
            </div>
          </div>
          <div ref={componentRef} className="w-full overflow-scroll">
            {/* -------- 
//expenses id,billno,expensesdate,description,total,createdAt
*/}
            <table className="w-full table-auto mx-4 mt-8">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[120px] font-medium text-black dark:text-white xl:pl-4">
                    วันที่เข้าพัก
                    <br />
                    วันที่คืนห้องพัก
                  </th>
                  <th className="min-w-[120px] font-medium text-black dark:text-white">
                    รายการเช่าห้องพัก
                    <br />
                    ประเภทห้อง
                  </th>
                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    เพิ่มคน/เตียง
                    <br />
                    เพิ่มผ้าห่ม
                  </th>
                  <th className="min-w-[130px] font-medium text-black dark:text-white">
                    ส่วนลดปกติ/เซลล์
                  </th>
                  <th className="min-w-[120px] font-medium text-black dark:text-white">
                    ยอดทั้งหมด
                    <br />
                    ยอดหักส่วนลด
                  </th>
                  <th className="min-w-[120px] font-medium text-black dark:text-white">
                    วิธีชำระเงิน
                    <br />
                    สถานะ
                  </th>
                </tr>
              </thead>
              <tbody>
                {checkinWithDateData.map((item, index) => {
                  // {item?.numofday}={item?.price}
                  // item?.total
                  if (item?.status === "ยกเลิกการเช่า") {
                    total = Number(total) + Number(item?.total);
                    net = Number(net) + Number(item?.net);
                  }
                  return (
                    <React.Fragment key={`cancle_${index}`}>
                      <tr>
                        <td className="border-b border-[#eee]  dark:border-strokedark xl:pl-4">
                          <h5 className="font-medium text-black dark:text-white">
                            {moment(item?.checkInDate).format("L")}
                          </h5>
                          <p className="text-sm"> {item?.checkInTime}</p>
                          {/* <p className="text-sm"> {item?.phone}</p> */}
                          <h5 className="font-medium text-black dark:text-white">
                            {moment(item?.checkOutDate).format("L")}
                          </h5>
                          <p className="text-sm"> {item?.checkOutTime}</p>
                        </td>
                        <td className="border-b border-[#eee]  dark:border-strokedark">
                          <h5 className="font-medium text-black dark:text-white">
                            {item?.roomno}x{item?.numofday}={item?.price}
                            <br />
                            {item?.roomtypename}
                          </h5>
                        </td>
                        <td className="border-b border-[#eee]  dark:border-strokedark">
                          <h5 className="font-medium text-black dark:text-white">
                            {item?.personplus}/{item?.bedplus}
                          </h5>
                          <h5 className="font-medium text-black dark:text-white">
                            {item?.blanketsmqty}/{item?.blanketsmprice}-
                            {item?.blanketbigqty}/{item?.blanketbigprice}
                            {/* blanketsmqty,blanketsmprice,blanketbigqty,blanketbigprice */}
                          </h5>
                        </td>
                        <td className="border-b border-[#eee]  dark:border-strokedark">
                          <h5 className="font-medium text-black dark:text-white">
                            {item?.priceDisNormal}/{item?.priceDisSaleDisSale}
                          </h5>
                        </td>
                        <td className="border-b border-[#eee]  dark:border-strokedark">
                          <h5 className="font-medium text-black dark:text-white">
                            {item?.net}
                            <br />
                            {item?.total}
                          </h5>
                        </td>
                        <td className="border-b border-[#eee] dark:border-strokedark">
                          <h5 className="font-medium text-black dark:text-white">
                            {item?.paymentMethod}
                            <br />
                            {item?.status}
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan="6"
                          className="border-b border-[#eee]  dark:border-strokedark xl:pl-4"
                        >
                          <h5 className="font-medium text-black dark:text-white">
                            หมายเหตุ
                          </h5>
                          <p className="text-sm"> {item?.comments}</p>
                          <h5 className="font-medium text-black dark:text-white">
                            สาเหตุยกเลิกการเช่า
                          </h5>
                          <p className="text-sm"> {item?.cancleText}</p>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <h5 className="font-medium text-black dark:text-white">
                      รวมเงิน{" "}
                    </h5>
                  </td>
                  <td>
                    <h5 className="font-medium text-black dark:text-white">
                      {total} บาท
                    </h5>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <h5 className="font-medium text-black dark:text-white">
                      รวมเงินหักส่วนลด{" "}
                    </h5>
                  </td>
                  <td>
                    <h5 className="font-medium text-black dark:text-white">
                      {net} บาท
                    </h5>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            {/* --------  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default RentCancleByDay;
