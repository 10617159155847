import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { facilityUrl, imgUrl } from "../../utils/baseUrl";

const EditFacility = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [selectedFile, setSectedFile] = useState(null);
  const [name, setName] = useState("");
  function readData(id) {
    axios.get(`${facilityUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
      setName(response.data?.name);
    });
  }

  useEffect(() => {
    readData(id);
  }, []);
  console.log(datas);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("name", name);
    formData.append("idEdit", id);
    setLoading(true);
    axios.post(`${facilityUrl}update.php`, formData).then(function (response) {
      console.log(response.data);
      readData(id);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading(false);
    });
  };
  return (
    <div>
      <div className="rounded-sm bg-red-500 border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            ชื่ออุปกรณ์ {id}
          </h3>
          <form onSubmit={handleOnSubmit}>
            <div className="p-6.5">
              <div>
                {datas?.icon != null ? (
                  <img
                    src={`${imgUrl}${datas?.icon}`}
                    className="rounded-lg mb-4 md:h-[300px] object-cover"
                  />
                ) : (
                  "-"
                )}
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  ชื่ออุปกรณ์
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="my-2">
                <label>รูปภาพ</label>
                <div className="flex flex-col items-start">
                  <input type="file" onChange={handleOnChange} />
                </div>
              </div>
              <button
                disabled={loading}
                type="submit"
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? <>กรุณาซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditFacility;
