import React, { createContext, useEffect, useState } from "react";
// import moment from "moment/min/moment-with-locales";
// import moment from "./moment";
// import moment from "moment";
export const RentContext = createContext();
// moment.locale("th");
// let mydate = moment(new Date()).locale("th").format("l");
const RentProvider = ({ children }) => {
  const [rooms, setRooms] = useState({});
  const [roomBooking, setRoomBooking] = useState({});
  const [adults, setAdults] = useState("1 Adult");
  const [kids, setKids] = useState("0 Kids");
  const [count, setCount] = useState(0);

  const [checkinDate, setCheckinDate] = useState(new Date());
  const [checkoutDate, setCheckoutDate] = useState(new Date());
  const [checkinTime, setCheckinTime] = useState("");
  const [checkoutTime, setCheckoutTime] = useState("");

  const [startDate, setStartDate] = useState(new Date());

  const [guarantee, setGuarantee] = useState("0");
  const [convertType, setConvertType] = useState("");
  const [payMethod, setPayMethod] = useState("");

  const [pricePerson, setPricePerson] = useState("0");
  const [priceBed, setPriceBed] = useState("0");

  //numofpersonplus,numofbedplus
  const [numPricePerson, setNumPricePerson] = useState("0");
  const [numPriceBed, setNumPriceBed] = useState("0");
  //numPricePerson, setNumPricePerson,numPriceBed, setNumPriceBed
  const [priceDisNormal, setPriceDisNormal] = useState("0");
  const [priceDisSaleDisSale, setPriceDisSaleDisSale] = useState("0");

  const [numDays, setNumdays] = useState("1");

  const [total, setTotal] = useState(0);

  useEffect(() => {
    setCount((pv) => pv + 1);
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    console.log("Click");
  };

  console.log("Rent Context: ", roomBooking);
  console.log("Check In Time: ", checkinTime);
  console.log("Check OUT Time: ", checkoutTime);
  console.log(
    "Start date ",
    count,
    ": ",
    checkinDate,
    ", ",
    " End date ",
    count,
    ": ",
    checkoutDate
  );
  console.log("NumDays: ", numDays);
  return (
    <RentContext.Provider
      value={{
        startDate,
        setStartDate,
        convertType,
        setConvertType,
        payMethod,
        setPayMethod,
        rooms,
        adults,
        setAdults,
        kids,
        setKids,
        roomBooking,
        setRoomBooking,
        checkinDate,
        setCheckinDate,
        checkoutDate,
        setCheckoutDate,
        checkinTime,
        setCheckinTime,
        checkoutTime,
        setCheckoutTime,
        guarantee,
        setGuarantee,
        priceDisNormal,
        setPriceDisNormal,
        priceDisSaleDisSale,
        setPriceDisSaleDisSale,
        pricePerson,
        setPricePerson,
        priceBed,
        setPriceBed,
        numDays,
        setNumdays,
        numPricePerson,
        setNumPricePerson,
        numPriceBed,
        setNumPriceBed,
        handleClick,
      }}
    >
      {children}
    </RentContext.Provider>
  );
};

export default RentProvider;
