import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";
import { bookingUrl } from "../../utils/baseUrl";
import moment from "moment";
moment.locale("th");
const ConfirmDeposit = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [checkinData, setCheckinData] = useState({});
  const [deposit, setDeposit] = useState("");
  const [comment, setComment] = useState("");
  function readCheckinData(id) {
    axios.get(`${bookingUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setCheckinData(response.data);
    });
  }

  useEffect(() => {
    readCheckinData(id);
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${bookingUrl}confirmbooking.php`, {
        idEdit: id,
        deposit,
        comment,
      })
      .then(function (response) {
        console.log(response.data);
        readCheckinData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };
  console.log("Booking: ", checkinData);
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการจองห้องพัก
          </h4>
          <div className="max-w-full  overflow-scroll">
            {/* -------- 
//expenses id,billno,expensesdate,description,total,createdAt
*/}
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    ลูกค้า
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    วันที่เข้าพัก
                  </th>
                  <th className="min-w-[130px] font-medium text-black dark:text-white">
                    วันที่คืนห้องพัก
                  </th>
                  <th className="min-w-[130px] font-medium text-black dark:text-white">
                    จำนวนผู้เข้าพัก
                  </th>
                  <th className="min-w-[130px] font-medium text-black dark:text-white">
                    จำนวนห้องพัก
                  </th>
                  <th className="min-w-[130px] font-medium text-black dark:text-white">
                    มัดจำ
                  </th>

                  <th className="font-medium text-black dark:text-white">
                    สถานะ
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-b border-[#eee] dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.firstName} {checkinData?.lastName}
                    </h5>
                    <p className="text-sm"> {checkinData?.phone}</p>
                    <p className="text-sm"> {checkinData?.email}</p>
                  </td>
                  <td className="border-b border-[#eee] dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {moment(checkinData?.checkInDate).format("L")}
                    </h5>
                    {/* <p className="text-sm"> {checkinData?.checkInTime}</p> */}
                    {/* <p className="text-sm"> {item?.phone}</p> */}
                  </td>
                  <td className="border-b border-[#eee] dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {moment(checkinData?.checkOutDate).format("L")}
                    </h5>
                    {/* <p className="text-sm"> {checkinData?.checkOutTime}</p> */}
                  </td>
                  <td className="border-b border-[#eee] dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      เด็ก:{checkinData?.kids}/ผู้ใหญ่:{checkinData?.adults}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.numOfRoom}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.deposit}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {checkinData?.status}
                    </h5>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* --------  */}
          </div>
          <div>
            <form onSubmit={handleSubmit}>
              <div className="p-6.5">
                <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    เงินมัดจำ
                  </label>
                  <input
                    type="number"
                    value={deposit}
                    onChange={(e) => setDeposit(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  />
                </div>
                <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    หมายเหตุเพิ่มเติม
                  </label>
                  <input
                    type="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  />
                </div>
                <button
                  disabled={loading}
                  type="submit"
                  className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                >
                  {loading ? <>กรุณาซักครู่..</> : <>บันทึก</>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmDeposit;
