import React, { useState, useEffect, useContext } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";
// import { roomTypeUrl, roomUrl, imgUrl } from "../../utils/baseUrl";
import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

/* import es from 'date-fns/locale/es';
registerLocale('es', es) */

/* import moment from "moment";
import "moment/locale/th"; */

import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import SimpleTimeInput from "react-simple-time-input";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import TimeField from "react-simple-timefield";

import {
  roomTypeUrl,
  roomUrl,
  imgUrl,
  pricePersonUrl,
  priceBedUrl,
  checkinUrl,
} from "../../utils/baseUrl";

import { RentContext } from "../../context/RentContext";
//Asia/Bangkok 09:27 pm +07
/* var dd = moment.locale(‘th’)
 this.date = moment().add(543, ‘year’).format(‘LLLL’) */
/* import moment from "moment";
moment.locale("th"); */
import moment from "moment/min/moment-with-locales";
moment.locale("th");
const RoomDetail = () => {
  const {
    adults,
    setAdults,
    convertType,
    setConvertType,
    payMethod,
    setPayMethod,
    startDate,
    checkinDate,
    setCheckinDate,
    checkoutDate,
    setCheckoutDate,
    checkinTime,
    setCheckinTime,
    checkoutTime,
    setCheckoutTime,
    setStartDate,
    roomBooking,
    setRoomBooking,
    guarantee,
    setGuarantee,
    priceDisNormal,
    setPriceDisNormal,
    priceDisSaleDisSale,
    setPriceDisSaleDisSale,
    pricePerson,
    setPricePerson,
    priceBed,
    setPriceBed,
    numPricePerson,
    setNumPricePerson,
    numPriceBed,
    setNumPriceBed,
    handleClick,
  } = useContext(RentContext);
  // onClick={() => setAdults(li.name)}
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [roomTypeData, setRoomTypeData] = useState([]);
  const [priceTypeData, setPriceTypeData] = useState([]);
  /*   const [priceDisNormal, setPriceDisNormal] = useState("100");
  const [priceDisSaleDisSale, setPriceDisSaleDisSale] = useState("500"); */
  const [plusPay, setPlusPay] = useState("");
  const [isCheckedPerson, setIsCheckedPerson] = useState(false);
  const [isCheckedBed, setIsCheckedBed] = useState(false);
  const [isCheckedDiscount, setIsCheckedDiscount] = useState(false);
  const [isCheckedSaleDiscount, setIsCheckedSaleDiscount] = useState(false);

  const [pricePersonData, setPricePersonData] = useState([]);
  const [priceBedData, setPriceBedData] = useState([]);
  /*   const [pricePerson, setPricePerson] = useState("");
  const [priceBed, setPriceBed] = useState(""); */
  const [value, onChange] = useState(new Date());
  const [time, setTime] = useState("14:00");
  // let mydate = moment(new Date().getTime()).locale("th").format("LT");
  /*   const SLASH_DMY = "DD/MM/YYYY";
  const SLASH_DMYHMS = "DD/MM/YYYY HH:mm:ss";
  const SLASH_YMD = "YYYY/MM/DD";
  const SLASH_YMDHMS = "YYYY/MM/DD HH:mm:ss";
  const DASH_DMY = "DD-MM-YYYY";
  const DASH_DMYHMS = "DD-MM-YYYY HH:mm:ss";
  const DASH_YMD = "YYYY-MM-DD";
  const DASH_YMDHMS = "YYYY-MM-DD HH:mm:ss"; */
  // const mydate = moment();

  function readRoomTypeData() {
    axios.get(`${roomTypeUrl}read.php`).then(function (response) {
      console.log(response.data);
      setRoomTypeData(response.data);
      setPriceTypeData(response.data);
    });
  }

  function readPricePerson() {
    axios.get(`${pricePersonUrl}read.php`).then(function (response) {
      console.log(response.data);
      setPricePersonData(response.data);
    });
  }
  function readPriceBed() {
    axios.get(`${priceBedUrl}read.php`).then(function (response) {
      console.log(response.data);
      setPriceBedData(response.data);
    });
  }
  // const [priceDisNormal, setPriceDisNormal] = useState("0");
  // const [priceDisSaleDisSale, setPriceDisSaleDisSale] = useState("0");
  const handlePersonOnChange = () => {
    setIsCheckedPerson(!isCheckedPerson);
  };
  const handleBedOnChange = () => {
    setIsCheckedBed(!isCheckedBed);
  };
  const handleDiscountOnChange = () => {
    setIsCheckedDiscount(!isCheckedDiscount);
    if (isCheckedDiscount) {
      setPriceDisNormal("100");
    } else {
      setPriceDisNormal("0");
    }
  };
  const handleSaleDiscountOnChange = () => {
    setIsCheckedSaleDiscount(!isCheckedSaleDiscount);
    if (isCheckedSaleDiscount) {
      let newPrice = roomBooking?.price - 1;
      setPriceDisSaleDisSale(newPrice);
    } else {
      setPriceDisSaleDisSale("0");
    }
  };

  useEffect(() => {
    readRoomTypeData();
    readPricePerson();
    readPriceBed();
    console.log(
      "Start date3: ",
      checkinDate,
      ", ",
      " End date4: ",
      checkoutDate
    );
    /*     setCheckinDate()
    setCheckoutDate() */
    // const x = moment().add(3,'hours')
    setCheckoutTime(moment(new Date().getTime()).add(3, "hours").format("LT"));
    setCheckinTime(moment(new Date().getTime()).format("LT"));
    console.log("CkIN Time::: ", checkinTime);
    console.log("CkOUT Time::: ", checkoutTime);
    /*     var beginningTime = moment('8:45am', 'h:mma');
    var endTime = moment('9:00am', 'h:mma');
    console.log(beginningTime.isBefore(endTime));
    var moment = require("moment")
var DutyDayStartTime = moment([13,05], "HH:mm")
var DutyDayEndTime = moment([13,05], "HH:mm")
DutyDayStartTime.diff(DutyDayEndTime, 'minutes')  */
    // 0
    // true
    //new Date().getTime()

    var beginningTime = checkinTime;
    //const diff = datediff(parseDate("1/1/2000"), parseDate("1/1/2001"))

    // var endTime = moment("17:00:00").format("LT");
    // console.log(beginningTime.isBefore(endTime)); // true
    console.log("Begin Time22: ", beginningTime);
    if (beginningTime.toString() > "17:00") {
      setIsCheckedDiscount(true);
      setPricePerson(100);
      console.log("Hello: ");
    } else {
      setIsCheckedDiscount(false);
    }
    // console.log("Now: ", checkoutTime);
  }, []);
  const handleConfirmClick = (e) => {
    e.preventDefault();

    if (convertType === "") {
      Swal.fire({
        position: "center",
        icon: "question",
        title: "กำหนดประเภทห้องด้วย",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    if (payMethod === "") {
      Swal.fire({
        position: "center",
        icon: "question",
        title: "ระบุวิธีชำระเงินด้วย",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    /*     setTotal(Number(adults[0]) + Number(kids[0]));
    console.log("Total IN Click: ", total);

    const newRooms = roomData.filter((room) => {
      return total <= room.maxPerson;
    });
    setRooms(newRooms); */

    //roomdetail
    // navigate("roomdetail");
    Swal.fire({
      title: "แจ้งเตือน",
      text: "กรุณายืนยันการชำระเงิน...",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      // console.log(id);
      if (result.isConfirmed) {
        // window.location.href = "roomdetail";
        navigate("/checkinroom/roompayment");
      }
    });
  };

  return (
    <div className="h-screen overflow-y-auto">
      {/* RoomDetail {JSON.stringify(roomBooking)} */}
      <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
        <h3 className="font-medium text-2xl text-black dark:text-white">
          กำหนดรายละเอียดห้องพัก
        </h3>
      </div>
      <div className="p-5">
        <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
          <div className="mb-4.5">
            <label className="mb-2.5 block text-black dark:text-white">
              วันที่เข้าพัก
            </label>
            <DatePicker
              selected={checkinDate}
              onChange={(date) => setCheckinDate(date)}
              className="w-full text-lg rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
            {/*             <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showTimeSelect
              name="startDate"
              timeIntervals={20}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              className="form-control"
            /> */}
            {/* <DateTimePicker onChange={setStartDate} value={startDate} /> */}
          </div>
          <div className="mb-4.5 bg-red-200">
            <label className="mb-2.5 block text-black dark:text-white">
              เวลาที่เข้าพัก
            </label>
            {/* moment(new Date().getTime()).locale("th").format("LT") */}
            <TimeField
              value={moment(new Date().getTime()).format("LT")}
              onChange={(event, value) => setCheckinTime(value)}
              style={{
                border: "2px solid #ddd",
                fontSize: 18,
                width: 170,
                padding: "12px 8px",
                color: "#333",
                borderRadius: 3,
              }}
            />
          </div>
          <div className="mb-4.5">
            <label className="mb-2.5 block text-black dark:text-white">
              วันที่คืนห้อง
            </label>
            <DatePicker
              selected={checkoutDate}
              onChange={(date) => setCheckoutDate(date)}
              className="w-full text-lg rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
          <div className="mb-4.5">
            <label className="mb-2.5 block text-black dark:text-white">
              เวลาที่คืนห้อง
            </label>
            {/*             const startTime = '12:30:00';
const durationInMinutes = '120';

const endTime = moment(startTime, 'HH:mm:ss').add(durationInMinutes, 'minutes').format('HH:mm');

// endTime is equal to "14:30" */}
            <TimeField
              value={moment(new Date().getTime()).add(3, "hours").format("LT")}
              onChange={(event, value) => setCheckoutTime(value)}
              style={{
                border: "2px solid #ddd",
                fontSize: 18,
                width: 170,
                padding: "12px 8px",
                color: "#333",
                borderRadius: 3,
              }}
            />
            {/*             <input
              type="time"
              value={checkoutTime}
              onChange={(e) => setCheckoutTime(e.target.value)}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            /> */}
          </div>
        </div>
        <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
          <div className="mb-4.5">
            <h1 className="mb-2.5 block text-black dark:text-white">เพิ่มคน</h1>
            {/* const [isCheckedPerson, setIsCheckedPerson] = useState(false); */}
            <div className="flex gap-2 w-full">
              <input
                type="checkbox"
                id="some_id"
                checked={isCheckedPerson}
                onChange={handlePersonOnChange}
              />
              <label htmlFor="some_id">
                {/* เพิ่มคน({isCheckedPerson ? "Yes" : "No"}) 
                numPricePerson, setNumPricePerson,numPriceBed, setNumPriceBed
                */}
              </label>
              {isCheckedPerson ? (
                <>
                  <div className="mb-2 w-full">
                    <div className="mb-4.5">
                      <label className="mb-2.5 block text-black dark:text-white">
                        จำนวนคน
                      </label>
                      <input
                        type="number"
                        value={numPricePerson}
                        onChange={(e) => setNumPricePerson(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                    <select
                      value={pricePerson}
                      onChange={(e) => setPricePerson(e.target.value)}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    >
                      <option value=""></option>
                      {/* <option value="0">มองเห็นทั้งหมด</option> */}
                      {pricePersonData.map((item, index) => (
                        <option key={index} value={item.price}>
                          {item.price}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="mb-4.5">
            {/* const [isCheckedBed, setIsCheckedBed] = useState(false); */}
            <h1 className="mb-2.5 block text-black dark:text-white">
              เพิ่มเตียง
            </h1>
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id2"
                checked={isCheckedBed}
                onChange={handleBedOnChange}
              />
              <label htmlFor="some_id2">
                {/* เพิ่มเตียง({isCheckedBed ? "Yes" : "No"}) 
                numPriceBed, setNumPriceBed
                */}
              </label>
              {isCheckedBed ? (
                <div className="mb-2 w-full">
                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      จำนวนเตียง
                    </label>
                    <input
                      type="number"
                      value={numPriceBed}
                      onChange={(e) => setNumPriceBed(e.target.value)}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    />
                  </div>
                  <select
                    value={priceBed}
                    onChange={(e) => setPriceBed(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  >
                    <option value=""></option>
                    {/* <option value="0">มองเห็นทั้งหมด</option> */}
                    {priceBedData.map((item, index) => (
                      <option key={index} value={item.price}>
                        {item.price}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
            </div>
          </div>
          <div className="mb-4.5">
            <h1 className="mb-2.5 block text-black dark:text-white">
              ส่วนลดปกติ
            </h1>
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id3"
                checked={isCheckedDiscount}
                onChange={handleDiscountOnChange}
              />
              <label htmlFor="some_id3">
                {/* Emergency({isCheckedDiscount ? "Yes" : "No"}) */}
              </label>
              {isCheckedDiscount ? (
                <>
                  <input
                    type="number"
                    value={priceDisNormal}
                    onChange={(e) => setPriceDisNormal(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  />
                </>
              ) : null}
            </div>
          </div>
          {/*       const [priceDisNormal, setPriceDisNormal] = useState("0");
const [priceDisSaleDisSale, setPriceDisSaleDisSale] = useState("0");; */}
          <div className="mb-4.5">
            <h1 className="mb-2.5 block text-black dark:text-white">
              ส่วนลดเซลล์
            </h1>
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id4"
                checked={isCheckedSaleDiscount}
                onChange={handleSaleDiscountOnChange}
              />
              <label htmlFor="some_id4">
                {/* ส่วนลดเซลล์({isCheckedSaleDiscount ? "Yes" : "No"}) */}
              </label>
              {isCheckedSaleDiscount ? (
                <>
                  <input
                    type="number"
                    value={priceDisSaleDisSale}
                    onChange={(e) => setPriceDisSaleDisSale(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
          <div className="mb-4.5">
            <label className="mb-2.5 block text-black dark:text-white">
              ค่าประกันห้อง
            </label>
            <input
              type="number"
              value={guarantee}
              onChange={(e) => setGuarantee(e.target.value)}
              className="w-full text-lg rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
          <div className="mb-4.5">
            <label className="mb-2.5 block text-black dark:text-white">
              กำหนดประเภทห้องพัก
            </label>
            <select
              value={convertType}
              onChange={(e) => setConvertType(e.target.value)}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            >
              <option value=""></option>
              {/* <option value="0">มองเห็นทั้งหมด</option> */}
              {priceTypeData.map((item, index) => (
                <option
                  key={index}
                  value={`${item.id}/${item.price}/${item.title}`}
                >
                  {item.title}({item.price})
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4.5">
            <label className="mb-2.5 block text-black dark:text-white">
              วิธีชำระเงิน
            </label>
            <select
              value={payMethod}
              onChange={(e) => setPayMethod(e.target.value)}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            >
              <option value=""></option>
              <option value="เงินสด">เงินสด</option>
              <option value="เงินโอน">เงินโอน</option>
            </select>
          </div>
          {/*           <div className="mb-4.5">
            <label className="mb-2.5 block text-black dark:text-white">
              จำนวนวันเข้าพัก
            </label>
            <input
              type="number"
              value={numDays}
              onChange={(e) => setNumdays(e.target.value)}
              className="w-full text-lg rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div> */}
        </div>
        {/* --------  */}
        <button
          className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
          disabled={loading}
          type="button"
          onClick={(e) => handleConfirmClick(e)}
        >
          {loading ? <>กรุณาซักครู่..</> : <>ชำระเงิน</>}
        </button>
        {/* --------  */}
      </div>
    </div>
  );
};

export default RoomDetail;
