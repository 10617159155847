import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";
import { employeeUrl, imgUrl } from "../../utils/baseUrl";

const AddEmployee = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      address: "",
      phone: "",
      positions: "",
      salary: "",
    },
  });
  const [loading, setLoading] = useState(false);
  //   employees id,firstName,lastName,address,phone,positions,createdAt
  const handleOnSubmit = (data) => {
    setLoading(true);

    //-------------
    axios
      .post(`${employeeUrl}add.php`, {
        firstname: data.firstname,
        lastname: data.lastname,
        address: data.address,
        phone: data.phone,
        positions: data.positions,
        salary: data.salary,
      })
      .then(function (response) {
        console.log(response.data);
        reset();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      });
    //-------------else

    //firstname,lastname,phone,email,password,pltype
  };
  return (
    <div>
      <div className="rounded-sm bg-red-500 border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          {/*           <h3 className="font-medium text-black dark:text-white">
    New Category
  </h3> */}
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="p-6.5">
              {/*           <div className="mb-4.5">
            <label className="mb-2.5 block text-black dark:text-white">
              ชื่ออุปกรณ์
            </label>
            <input
              type="text"
              name="name"
              {...register("name", {
                required: true,
              })}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
            {errors.name && (
              <div className="my-3 text-normal text-danger italic">
                ป้อนชื่ออุปกรณ์
              </div>
            )}
          </div> */}
              <div className="mb-2">
                <label>ชื่อจริง</label>
                <input
                  type="text"
                  name="firstname"
                  {...register("firstname", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.firstname && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    ป้อนชื่อจริง
                  </div>
                )}
              </div>
              {/* //firstname,lastname,phone,email,password,pltype */}
              <div className="mb-2">
                <label>นามสกุล</label>
                <input
                  type="text"
                  name="lastname"
                  {...register("lastname", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.lastname && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    ป้อนนามสกุล
                  </div>
                )}
              </div>

              <div className="mb-2">
                <label>ที่อยู่</label>
                <input
                  type="text"
                  name="address"
                  {...register("address", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.address && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    ป้อนที่อยู่
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label>โทรศัพท์</label>
                <input
                  type="tel"
                  name="phone"
                  {...register("phone", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.phone && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    ป้อนโทรศัพท์
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label>ตำแหน่ง</label>
                <input
                  type="text"
                  name="positions"
                  {...register("positions", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.positions && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    ป้อนตำแหน่ง
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label>เงินเดือน</label>
                <input
                  type="text"
                  name="salary"
                  {...register("salary", {
                    required: true,
                  })}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {errors.salary && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    ป้อนตำแหน่ง
                  </div>
                )}
              </div>

              <button
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                disabled={loading}
                type="submit"
              >
                {loading ? <>กรุณาซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEmployee;
