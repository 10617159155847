import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import {
  Home,
  Category,
  Course,
  Bank,
  Member,
  Profile,
  SignIn,
  Status,
  Admin,
  Report,
  Room,
  RoomType,
  Facility,
  CheckIn,
  Customer,
  Employee,
  LoginHistory,
  Booking,
  PriceAddBed,
  PriceAddPerson,
  Expenses,
  Sales,
  Product,
  CheckOut,
  CheckinRoom,
  Sale,
  SumReport,
} from "./pages";

import DefaultLayout from "./layout/DefaultLayout";
import { AddCategory, EditCategory, ViewCategory } from "./components/category";
import { AddType, EditType, ViewType } from "./components/type";
import { AddFacility, EditFacility, ViewFacility } from "./components/facility";
import {
  AddEmployee,
  EditEmployee,
  ViewEmployee,
  AddSalary,
} from "./components/employee";
import { AddRoom, EditRoom, ViewRoom } from "./components/room";
import { AddProduct, EditProduct, ViewProduct } from "./components/product";
import {
  AddPricePerson,
  EditPricePerson,
  ViewPricePerson,
} from "./components/priceperson";
import { AddPriceBed, EditPriceBed, ViewPriceBed } from "./components/pricebed";
import { AddAdmin, EditAdmin, ViewAdmin } from "./components/admin";
import { AddExpenses, EditExpenses, ViewExpenses } from "./components/expenses";
import { ViewRoomCheckin, RoomCheckOut } from "./components/checkout";
import {
  ViewAllRoomCheckin,
  RoomCheckin,
  NewRoomCheckin,
  PrintBill,
  SearchRoom,
  RoomDetail,
  ConfirmBooking,
  RoomPayment,
} from "./components/checkin";
import {
  HomeReportWithDay,
  HomeReportWithMonth,
  HomeReportWithYear,
} from "./components/home";

import {
  RentByDay,
  RentByMonth,
  RentByYear,
  ReportExpenses,
  ReportSalary,
  BookingByDay,
  ReportSale,
  ChackOutByDay,
  RentCancleByDay,
} from "./components/reports";

import { ViewBooking, ConfirmDeposit, AddBooking } from "./components/booking";
import { SaleProduct, PrintSaleBill, ShowProduct } from "./components/sale";
import { Report1, Report2, Report3 } from "./components/sumreport";
const Layout = () => {
  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  );
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />, //HomeReportWithDay, HomeReportWithMonth, HomeReportWithYear
        children: [
          {
            path: "",
            element: <HomeReportWithDay />,
          },
          {
            path: "rpwithmonth",
            element: <HomeReportWithMonth />,
          },
          {
            path: "rpwithyear",
            element: <HomeReportWithYear />,
          },
        ],
      },
      {
        path: "/category", //AddCategory, EditCategory, ViewCategory
        element: <Category />,
        children: [
          {
            path: "",
            element: <ViewCategory />,
          },
          {
            path: "addcategory",
            element: <AddCategory />,
          },
          {
            path: "editcategory/:id",
            element: <EditCategory />,
          },
        ],
      },
      {
        path: "/roomtype",
        element: <RoomType />,
        children: [
          {
            path: "", // AddType, EditType, ViewType
            element: <ViewType />,
          },
          {
            path: "addtype",
            element: <AddType />,
          },
          {
            path: "edittype/:id",
            element: <EditType />,
          },
        ],
      },
      {
        path: "/facility",
        element: <Facility />, //AddFacility, EditFacility, ViewFacility
        children: [
          {
            path: "",
            element: <ViewFacility />,
          },
          {
            path: "addfacility",
            element: <AddFacility />,
          },
          {
            path: "editfacility/:id",
            element: <EditFacility />,
          },
        ],
      },
      {
        path: "/priceofbed",
        element: <PriceAddBed />, //AddPriceBed, EditPriceBed, ViewPriceBed
        children: [
          {
            path: "",
            element: <ViewPriceBed />,
          },
          {
            path: "addpriceofbed",
            element: <AddPriceBed />,
          },
          {
            path: "editpriceofbed/:id",
            element: <EditPriceBed />,
          },
        ],
      },
      {
        path: "/priceofperson",
        element: <PriceAddPerson />, //AddPricePerson, EditPricePerson, ViewPricePerson
        children: [
          {
            path: "",
            element: <ViewPricePerson />,
          },
          {
            path: "addpriceofperson",
            element: <AddPricePerson />,
          },
          {
            path: "editpriceofperson/:id",
            element: <EditPricePerson />,
          },
        ],
      },
      {
        path: "/rooms",
        element: <Room />, //AddRoom, EditRoom, ViewRoom
        children: [
          {
            path: "",
            element: <ViewRoom />,
          },
          {
            path: "addroom",
            element: <AddRoom />,
          },
          {
            path: "editroom/:id",
            element: <EditRoom />,
          },
        ],
      },
      {
        path: "/employee",
        element: <Employee />,
        children: [
          //AddEmployee, EditEmployee, ViewEmployee
          {
            path: "",
            element: <ViewEmployee />,
          },
          {
            path: "addemployee",
            element: <AddEmployee />,
          },
          {
            path: "editemployee/:id",
            element: <EditEmployee />,
          },
          {
            path: "addsalary", //addsalary/:id
            element: <AddSalary />,
          },
        ],
      },
      {
        path: "/expenses",
        element: <Expenses />, //AddExpenses, EditExpenses, ViewExpenses
        children: [
          {
            path: "",
            element: <ViewExpenses />,
          },
          {
            path: "addexpenses",
            element: <AddExpenses />,
          },
          {
            path: "editexpenses/:id",
            element: <EditExpenses />,
          },
        ],
      },
      {
        path: "/customer",
        element: <Customer />,
      },
      {
        path: "/checkin",
        element: <CheckIn />,
      },
      {
        path: "/checkinroom",
        element: <CheckinRoom />, //ViewRoomCheckin, CheckinRoom, PrintBill  RoomDetail,ConfirmBooking
        children: [
          {
            path: "",
            element: <ViewAllRoomCheckin />,
          },
          {
            path: "searchroom",
            element: <SearchRoom />,
          },
          {
            path: "roomdetail",
            element: <RoomDetail />,
          },
          {
            path: "roompayment",
            element: <RoomPayment />,
          },
          {
            path: "confirmbooking",
            element: <ConfirmBooking />,
          },
          {
            path: "roomcheckin",
            element: <RoomCheckin />,
          },
          {
            path: "newroomcheckin",
            element: <NewRoomCheckin />,
          },
          {
            path: "printbill/:id",
            element: <PrintBill />,
          },
        ],
      },
      {
        path: "/checkout",
        element: <CheckOut />, //ViewRoomCheckin, RoomCheckOut
        children: [
          {
            path: "",
            element: <ViewRoomCheckin />,
          },
          {
            path: "roomcheckout/:id",
            element: <RoomCheckOut />,
          },
        ],
      },
      {
        path: "/sumreport",
        element: <SumReport />, //Report1, Report2, Report3
        children: [
          {
            path: "",
            element: <Report1 />,
          },
          {
            path: "report2",
            element: <Report2 />,
          },
          {
            path: "report3",
            element: <Report3 />,
          },
        ],
      },
      {
        path: "/booking",
        element: <Booking />, // ViewBooking, ConfirmDeposit
        children: [
          {
            path: "",
            element: <ViewBooking />,
          },
          {
            path: "addbooking",
            element: <AddBooking />,
          },
          {
            path: "confirmdeposit/:id",
            element: <ConfirmDeposit />,
          },
        ],
      },
      {
        path: "/product",
        element: <Product />, //AddProduct, EditProduct, ViewProduct
        children: [
          {
            path: "",
            element: <ViewProduct />,
          },
          {
            path: "addproduct",
            element: <AddProduct />,
          },
          {
            path: "editproduct/:id",
            element: <EditProduct />,
          },
        ],
      },
      {
        path: "/sales",
        element: <Sales />, //SaleProduct, PrintSaleBill, ShowProduct
        children: [
          {
            path: "",
            element: <ShowProduct />,
          },
          {
            path: "buy",
            element: <SaleProduct />,
          },
          {
            path: "printsalebill/:id",
            element: <PrintSaleBill />,
          },
        ],
      },
      {
        path: "/allreport",
        element: <Report />, //RentByDay, RentByMonth, RentByYear, ReportExpenses, ReportSalary
        children: [
          {
            path: "",
            element: <RentByDay />,
          },
          {
            path: "rentbymonth",
            element: <RentByMonth />,
          },
          {
            path: "rentbyyear",
            element: <RentByYear />,
          },
          {
            path: "checkoutbyday",
            element: <ChackOutByDay />,
          },
          {
            path: "canclebyday",
            element: <RentCancleByDay />,
          },
          {
            path: "reportexpenses",
            element: <ReportExpenses />,
          },
          {
            path: "reportsalary",
            element: <ReportSalary />,
          },
          {
            path: "reportbooking",
            element: <BookingByDay />,
          },
          {
            path: "reportsale",
            element: <ReportSale />,
          },
        ],
      },
      {
        path: "/admin",
        element: <Admin />, //AddAdmin, EditAdmin, ViewAdmin
        children: [
          {
            path: "",
            element: <ViewAdmin />,
          },
          {
            path: "addadmin",
            element: <AddAdmin />,
          },
          {
            path: "editadmin/:id",
            element: <EditAdmin />,
          },
        ],
      },
    ],
  },
  {
    path: "signin",
    element: <SignIn />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
